'use strict';

angular.module('sdk').controller('PreviewController', function ($scope, $location, $timeout, designDataOutput, designDataUtils, previewService, popupService, getModifiedDesignDataForPreview) {

    var search = $location.search();

    var previewAdapter = previewService.getAdapter();

    $scope.designDataUtils = designDataUtils;
    $scope.designDataOutput = designDataOutput;
    $scope.isUserInteracted = false;
    $scope.isRendering = true;
    $scope.isAdapterReady = false;
    $scope.previewAdapter = previewAdapter.name;

    $scope.$on('contentReady', initializeAdapter);
    $scope.$watch('designDataOutput', updateDesignData, true);



    // call when preview has been initialized
    function onInitialized() {
        updateDesignData(designDataOutput);
    }

    // render start
    function onRender() {
        $scope.isRendering = true;
    }

    // when preview is finished rendering
    function onRendered(allViewsRendered) {
        $scope.isRendering = false;
        $scope.isAdapterReady = true;

        if (search.print) {
            popupService.openPopup('print');
        }
    }

    function onPreviewUserInteracted() {
        $scope.isUserInteracted = true;
    }

    function call(method) {
        previewAdapter[method].apply(previewAdapter, Array.prototype.slice.call(arguments, 1));
    }

    function initializeAdapter() {
        if (previewAdapter.isInitialized) {
            return;
        }

        call('initialize', $scope, {
            'onPreviewUserInteracted': onPreviewUserInteracted,
            'onInitialized': onInitialized,
            'onRendered': onRendered,
            'onRender': onRender
        });
    }

    // Update design data
    function updateDesignData(designDataOutput) {
        if (!previewAdapter.isInitialized) {
            return;
        }

        designDataOutput = getModifiedDesignDataForPreview(designDataOutput);

        call('update', designDataOutput);
    }
});
