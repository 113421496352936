'use strict';

angular.module('sdk').factory('getFlatCanvasLayerList', function (_) {

    return function getFlatCanvasLayerList(layerList) {
        var flatLayerList = [];

        _.each(layerList, function (layer) {
            if (_.isArray(layer)) {
                var layerList = getFlatCanvasLayerList(layer);

                _.each(layerList, function (layer) {
                    flatLayerList.push(layer);
                });
            } else {
                flatLayerList.push(layer);
            }
        });

        return flatLayerList;
    }
});
