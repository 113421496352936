'use strict';

angular.module('sdk').factory('colorService', function () {

    function hexToRgb(hex) {
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

        return {
            'r': parseInt(result[1], 16),
            'g': parseInt(result[2], 16),
            'b': parseInt(result[3], 16)
        };
    }

    function rgbToHsl(r, g, b) {
        r = mapRange(r, 0, 255, 0, 1);
        g = mapRange(g, 0, 255, 0, 1);
        b = mapRange(b, 0, 255, 0, 1);

        var max = Math.max(r, g, b);
        var min = Math.min(r, g, b);

        var h, s, l = (max + min) / 2;

        var d;

        if (max === min) {
            h = s = 0; //achromatic
        } else {
            d = max - min;
            s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

            switch (max) {
                case r: h = (g - b) / d + (g < b ? 6 : 0); break;
                case g: h = (b - r) / d + 2; break;
                case b: h = (r - g) / d + 4; break;
            }

            h /= 6;
        }

        return {
            'h': h,
            's': s,
            'l': l
        };
    }

    //h, s, l is in range [0..1]
    function hslToRgb(h, s, l) {
        var r, g, b, q, p;

        if (s === 0) {
            r = g = b = l;
        } else {
            q = l < 0.5 ? l * (1 + s) : l + s - l * s;
            p = 2 * l - q;

            r = hue2rgb(p, q, h + 1/3);
            g = hue2rgb(p, q, h);
            b = hue2rgb(p, q, h - 1/3);
        }

        return {
            'r': roundToRgbColor(r),
            'g': roundToRgbColor(g),
            'b': roundToRgbColor(b)
        };
    }

    function rgbToHex(r, g, b) {
        return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
    }

    function hexToHsl(hex) {
        var rgb = hexToRgb(hex);
        return rgbToHsl(rgb.r, rgb.g, rgb.b);
    }

    function hslToHex(h, s, l) {
        var rgb = hslToRgb(h, s, l);
        return rgbToHex(rgb.r, rgb.g, rgb.b);
    }

    function hue2rgb(p, q, t) {
        if (t < 0) t += 1;
        if (t > 1) t -= 1;
        if (t < 1/6) return p + (q - p) * 6 * t;
        if (t < 1/2) return q;
        if (t < 2/3) return p + (q - p) * (2/3 - t) * 6;

        return p;
    }

    function clamp(val, min, max) {
        return val < min? min : (val > max? max : val);
    }

    function roundToRgbColor(num) {
        return clamp(Math.round(num * 255), 0, 255);
    }

    function mapRange(value, sourceFrom, sourceTo, destFrom, destTo) {
        return destFrom + (destTo - destFrom) * (value - sourceFrom) / (sourceTo - sourceFrom);
    }

    return {
        'hexToRgb': hexToRgb,
        'hexToHsl': hexToHsl,
        'rgbToHsl': rgbToHsl,
        'rgbToHex': rgbToHex,
        'hslToRgb': hslToRgb,
        'hslToHex': hslToHex,
        'hue2rgb': hue2rgb,
        'clamp': clamp,
        'roundToRgbColor': roundToRgbColor,
        'mapRange': mapRange
    }
});
