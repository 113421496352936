'use strict';

angular.module('sdk').factory('transformDesignDataToLayers', ['_', 'getHexFromCode', 'getCanvasLayers', 'getLayerName', function (_, getHexFromCode, getCanvasLayers, getLayerName) {

    var layers = getCanvasLayers();

    return function transformDesignDataToLayers(designData) {
        var images = [];

        angular.copy(layers[0].layers[getLayerName()].frontView, images);

        _.each(images, function (image) {
            if (image.property) {
                image.color = getHexFromCode((function (query, object) {
                    var value = object;
                    _.each(query.split('.'), function (key) {
                        value = value[key];
                    });
                    return value;
                })(image.property, designData));
            }
        });

        return images;
    }
}]);
