'use strict';

angular.module('sdk').config(function ($httpProvider) {
    $httpProvider.interceptors.push('dataModelVersionInterceptor');
});

angular.module('sdk').factory('projectLoader', function (_, $http, RequestCanceler, loadSaveOrderConfig, consoleService) {
    var requestCanceler = new RequestCanceler();

    function load(projectID) {
        requestCanceler.cancel();

        var url = _.template(loadSaveOrderConfig.loadUrl)({'projectID': projectID});

        return $http({
            method: 'GET',
            url: url,
            dataModelVersionInterceptor: true,
            timeout: requestCanceler.promise
        })
        .error(function (data, status, headers, config) {
            consoleService.reportError('HttpGetError', 'Unable to load project data (status: ' + status + ', message: ' + (data.error || data) + ')', url);
        });
    }

    function cancel() {
        requestCanceler.cancel();
    }

    return {
        'load': load,
        'cancel': cancel
    }
});
