'use strict';

angular.module('sdk').value('imageServerPath', 'http://customizer-media.orangelv.com/upload');

angular.module('sdk').value('orderConfig', {});

angular.module('sdk').value('shareConfig', {
    facebook: 'http://www.facebook.com/sharer.php?u={url}',
    twitter: 'http://twitter.com/share?url={url}&text={text}&hashtags={hashtags}&related={related}{via}',
    google: 'https://plus.google.com/share?url={url}',
    mail: 'mailto:?body={text}'
});

angular.module('sdk').config(function ($sceDelegateProvider) {
    var protocol = window.location.protocol;

    $sceDelegateProvider.resourceUrlWhitelist([
        'self',
        protocol + '//customizer-*.orangelv.com/**'
    ]);

    $sceDelegateProvider.resourceUrlBlacklist([]);
});

angular.module('sdk').value('navigationHistory', []);
