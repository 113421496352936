'use strict';

angular.module('sdk').factory('generateList', function (_, getHashFromName) {

    return function (nameList) {
        var itemMap = {},
            allCodes = [];

        _.each(nameList, function (name) {
            var hash = getHashFromName(name);

            allCodes.push(hash);

            itemMap[hash] = {
                'code': hash,
                'name': name
            };
        });

        return {
            'itemMap': itemMap,
            'allCodes': allCodes
        }
    }

});
