'use strict';

angular.module('jarden').directive('vendorLogo', function () {
    return {
        restrict: 'E',
        replace: true,
        scope: {},
        templateUrl: 'vendor/logo.html'
    }
});
