'use strict';

angular.module('jarden').factory('previewUploader', function (_, async, $q, $window, $http, previewService, loadSaveOrderConfig) {
    var previewAdapter = previewService.getAdapter();
    var _projectID;
    var _previews;
    resetData()

    function fetchSnapshots() {
        var deferred = $q.defer();

        $http({
            method: 'POST',
            url: _.template(loadSaveOrderConfig.previewUrl)({
                projectID: _projectID,
                viewName: ''
            }).slice(0, -1),
            data: _previews
        })
            .success(function (data) {
                setData(data);
                deferred.resolve();
            })
            .error(function () {
                deferred.reject('Unable to fetch preview files');
            });

        return deferred.promise;
    }

    function isValid() {
        var data = getData();
        var valid = Object.keys(data).length > 0;

        _.forEach(data, function (val) {
            if (!val) {
                valid = false;
                return false;
            }
        });

        return valid;
    }

    function uploadSnapshots(snapshots) {
        var deferred = $q.defer();

        snapshots = snapshots || [];

        async.eachLimit(snapshots, 1, function (snapshot, callback) {
            uploadSnapshot(snapshot)
                .catch(function (err) {
                    callback(err);
                })
                .then(function () {
                    callback();
                });
        }, function (err) {
            if (err) {
                deferred.reject(err);
            } else {
                deferred.resolve();
            }
        });

        return deferred.promise;
    }

    function uploadSnapshot(snapshot) {
        var deferred = $q.defer();

        $http({
            method: 'POST',
            url: _.template(loadSaveOrderConfig.previewUrl)({
                projectID: _projectID,
                viewName: snapshot.viewName
            }),
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: 'file=' + $window.encodeURIComponent(snapshot.imgUrl)
        })
        .success(function (data) {
            _previews[snapshot.viewName] = data.filePath;
            deferred.resolve();
        })
        .error(function () {
            deferred.reject('Unable to save preview file: ' + snapshot.viewName);
        });

        return deferred.promise;
    }

    function resetData() {
        _previews = _.transform(previewAdapter.options.snapshotList, function (result, snapshot) {
            result[snapshot.viewName] = '';
        }, {});
    }

    function setData(newData) {
        _previews = _.mapValues(_previews, function(value, key){
            return newData[key] || ''
        })
    }

    function getData() {
        return _.transform(_previews, function (result, url, view) {
            result['preview' + view.charAt(0).toUpperCase() + view.slice(1)] = url;
        });
    }

    function upload(projectID) {
        _projectID = projectID;

        var deferred = $q.defer();

        fetchSnapshots()
            .catch(deferred.reject)
            .then(function () {
                if (isValid()) {
                    deferred.resolve();
                } else {
                    previewService
                        .getSnapshots()
                        .catch(deferred.reject)
                        .then(function (snapshots) {
                            uploadSnapshots(snapshots)
                                .catch(deferred.reject)
                                .then(deferred.resolve);
                        });
                }
            });

        return deferred.promise;
    }

    return {
        'resetData': resetData,
        'setData': setData,
        'getData': getData,
        'upload': upload
    }
});
