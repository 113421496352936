'use strict';

angular.module('sdk').factory('getHashFromName', function (_) {

    return function getHashFromName(name) {
        var segments = [],
            i = 0;

        if (!name) {
            return '';
        }

        segments = name.split(' ');

        segments = _.map(segments, function (segment) {
            segment = segment.replace(/\W/g, '');

            if (segment.length === 1) {
                segment = segment.toLowerCase();
            } else if (segment !== segment.toUpperCase()) {
                // process only if segment is not an abbreviation
                // upper case first letter except first segment
                if (i > 0) {
                    segment = segment.substr(0,1).toUpperCase() + segment.substr(1);
                } else {
                    segment = segment.substr(0,1).toLowerCase() + segment.substr(1);
                }
            }

            i++;

            return segment;
        });

        return segments.join('');
    }

});
