'use strict';

angular.module('sdk').factory('flattenDashed', function (_) {

    function flattenDashed(targetObject, resultObject, prefix) {
        var resultObject = resultObject || {};
        prefix = prefix || ''

        _.each(targetObject, function (sourceValue, key) {
            var path = prefix + key;

            if (_.isObject(sourceValue)) {
                flattenDashed(sourceValue, resultObject, (path + '-'));
            } else {
                resultObject[path] = sourceValue;
            }
        });

        return resultObject;
    }

    return flattenDashed;
});
