'use strict';

angular.module('sdk').directive('popup', function (popupService) {
    return {
        restrict: 'E',
        replace: true,
        transclude: true,
        templateUrl: 'popup.html',
        link: function(scope, element, attributes) {
            element.bind('click', function (e) {
                e.stopPropagation();
            });

            scope.type = attributes.type || 'standart';
            scope.title = attributes.title;
            scope.close = popupService.closePopup;
            popupService.changeTitle = function (title) {
                scope.title = title;
            }
        }
    }
});
