'use strict';

angular.module('sdk').directive('lightbox', function (popupService, vendorConfig) {
    return {
        restrict: 'E',
        replace: true,
        templateUrl: 'lightbox.html',
        link: function(scope, element, attributes) {
            scope.closePopup = popupService.closePopup;
            scope.orderSectionTitle = vendorConfig.ui.orderSectionTitle;
            scope.shareSectionTitle = vendorConfig.ui.shareSectionTitle;
        }
    }
});
