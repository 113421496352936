'use strict';

angular.module('sdk').directive('customContextMenu', function ($document, projectTitle) {
    return {
        restrict: 'E',
        replace: true,
        templateUrl: 'customContextMenu.html',
        link: function (scope, element, attributes) {

            var parent = element.parent();

            var menuWidth = element.prop('offsetWidth');
            var menuHeight = element.prop('offsetHeight');

            scope.projectTitle = projectTitle;

            function toggle(event) {
                event.stopImmediatePropagation();
                if (scope.visible) {
                    hide();
                } else {
                    event.preventDefault();
                    reposition(event);
                    show();
                }
            }

            function show() {
                scope.$apply(function() {
                    scope.visible = true;
                    $document.on('mousedown', tryHide);
                    $document.on('touchstart', tryHide);
                });
            }

            
            function hide() {
                scope.$apply(function() {
                    scope.visible = false;
                    $document.off('mousedown', tryHide);
                    $document.off('touchstart', tryHide);
                });
            }

            function reposition(event) {
                var previewWidth = parent.prop('offsetWidth');
                var previewHeight = parent.prop('offsetHeight');

                var menuLeftOffset = Math.min(event.pageX, previewWidth - menuWidth);
                var menuTopOffset = Math.min(event.pageY, previewHeight - menuHeight);

                element.css('left', menuLeftOffset + 'px');
                element.css('top', menuTopOffset + 'px');
            }

            function tryHide(event) {
                // Do not execute 'hide' if this event is
                // going to be followed by a 'contextmenu' event
                if (event.button !== 2 && !event.ctrlKey) {
                    hide();
                }
            }

            element.on('mousedown', function(event) {
                event.stopPropagation();
            });
            element.on('touchstart', function(event) {
                event.stopPropagation();
            });
            element.on('click', tryHide);

            parent.on('contextmenu', toggle);
            $document.on('contextmenu', hide);
        }
    };
});
