'use strict';

angular.module('sdk').factory('CanvasAdapter', function (_, $rootScope, $q, $timeout, $window, getCanvasLayers, shadowBlendingMethod, templateIdHandler, consoleService) {
    var CanvasPreview = BacisCanvasPreviewProvider(angular, _, $q, getCanvasLayers, shadowBlendingMethod, templateIdHandler);

    var CanvasAdapter = function (options) {
        this.name = 'canvas';
        this.options = angular.extend({}, options);
        this.rendered = [];
        this.isInitialized = false;
    };

    CanvasAdapter.prototype.initialize = function (scope, callbacks) {
        consoleService.logEvent('CanvasPreview', 'Initialize');

        this.scope = scope;
        this.callbacks = callbacks;

        this.element = angular.element(document.getElementById('canvasPreviewContainer'));
        this.canvasPreview = new CanvasPreview(this.element, this.options);

        this.canvasPreview.initialize(this.options.collections[0].code);
        this._bind();

        this.isInitialized = true;

        this.callbacks.onInitialized && this.callbacks.onInitialized();
    };

    CanvasAdapter.prototype.supported = function () {
        var element = document.createElement('canvas');

        return !!(element.getContext && element.getContext('2d'));
    };

    CanvasAdapter.prototype.configure = function (options) {
        this.options = angular.extend(this.options, options || {});

        return this;
    };

    CanvasAdapter.prototype.changeActiveView = function (activeView) {
        consoleService.logEvent('CanvasPreview', 'UserChangedView', activeView);

        this.onRender();
        this.canvasPreview.changeActiveView(activeView).then(
            angular.bind(this, function (allViews) {
                consoleService.logEvent('CanvasPreview', 'NotifyRender');

                this.onRendered(allViews);
            }),
            function() {}
        );
    };

    CanvasAdapter.prototype.zoomOut = function () {

    };

    CanvasAdapter.prototype.update = _.debounce(function (designData) {
        consoleService.logEvent('CanvasPreview', 'Update');

        this.onRender();

        this.canvasPreview.update(designData).then(
            angular.bind(this, function (allViews) {
                this.onRendered(allViews);
            }),
            function() {}
        );

        return this;
    }, 50);

    CanvasAdapter.prototype.onRender = function () {
        this.callbacks.onRender && this.callbacks.onRender();
    };

    CanvasAdapter.prototype.onRendered = function (allViews) {
        this.callbacks.onRendered && this.callbacks.onRendered(allViews);

        return this;
    };

    CanvasAdapter.prototype._bind = function () {
        angular.element($window).bind('resize', angular.bind(this, this._resize));
        $rootScope.$on('switch_mobile', angular.bind(this, this._resizeAfterLayout));
    };

    CanvasAdapter.prototype._resize = function () {
        this.scope.$apply(
            angular.bind(this, function () {
                this.onRender();

                this.canvasPreview.resize().then(
                    angular.bind(this, function (allViews) {
                        this.onRendered(allViews);
                    }),
                    angular.bind(this, function () {
                        //Stack canceled
                    })
                );
            })
        )
    };

    CanvasAdapter.prototype._resizeAfterLayout = function () {
        var body = angular.element(document.getElementsByTagName('body'));

        this.onRender();

        this.canvasPreview.resize().then(
            angular.bind(this, function (allViews) {
                this.onRendered(allViews);
            }),
            angular.bind(this, function () {
                //Stack canceled
            })
        );
    };

    CanvasAdapter.prototype.getSnapshot = function (viewName, sizeName) {
        return this.canvasPreview.getViewDataUrl(viewName, sizeName);
    };

    CanvasAdapter.prototype.getSnapshots = function () {
        var snapshots = [];

        _.each(this.options.snapshotList, function (snapshot) {
            snapshots.push(this.getSnapshot(snapshot.viewName, snapshot.size));
        }, this);

        return $q.all(snapshots);
    };

    return CanvasAdapter;
});
