'use strict';

angular.module('sdk').directive('rosterSizeSummary', function (_, rosterSizes, rosterDataOutput) {
    return {
        restrict: 'E',
        replace: true,
        templateUrl: 'rosterSizeSummary.html',
        controller: function($scope, $element, $attrs) {

            var sizeSumMap = {};
            var sizeSumList = _.map(rosterSizes.itemMap, function(size){
                var sumEntry = {
                    qty: 0,
                    name: size.printName
                };
                sizeSumMap[size.code] = sumEntry;
                return sumEntry;
            })

            $scope.summary = [];
            $scope.total = 0;

            $scope.rosterDataOutput = rosterDataOutput;
            $scope.$watchCollection('rosterDataOutput', function (rosterDataOutput) {
                $scope.total = 0;
                _.each(sizeSumMap, function(sumEntry){
                    sumEntry.qty = 0;
                })
                _.each(rosterDataOutput, function (entry) {
                    var qty = parseInt(entry.qty, 10);
                    sizeSumMap[entry.size].qty += qty;
                    $scope.total += qty;
                });

                $scope.summary = _.reject(sizeSumList, function(sumEntry){
                    return sumEntry.qty === 0;
                })
            });
        }
    }
});
