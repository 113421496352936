;(function () {

  var object = typeof exports != 'undefined' ? exports : this; // #8: web workers
  var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';

  function InvalidCharacterError(message) {
    this.message = message;
  }
  InvalidCharacterError.prototype = new Error;
  InvalidCharacterError.prototype.name = 'InvalidCharacterError';

  // encoder
  // [https://gist.github.com/999166] by [https://github.com/nignag]
  object.btoa || (
  object.btoa = function (input) {
    for (
      // initialize result and counter
      var block, charCode, idx = 0, map = chars, output = '';
      // if the next input index does not exist:
      //   change the mapping table to "="
      //   check if d has no fractional digits
      input.charAt(idx | 0) || (map = '=', idx % 1);
      // "8 - idx % 1 * 8" generates the sequence 2, 4, 6, 8
      output += map.charAt(63 & block >> 8 - idx % 1 * 8)
    ) {
      charCode = input.charCodeAt(idx += 3/4);
      if (charCode > 0xFF) {
        throw new InvalidCharacterError("'btoa' failed: The string to be encoded contains characters outside of the Latin1 range.");
      }
      block = block << 8 | charCode;
    }
    return output;
  });

  // decoder
  // [https://gist.github.com/1020396] by [https://github.com/atk]
  object.atob || (
  object.atob = function (input) {
    input = input.replace(/=+$/, '');
    if (input.length % 4 == 1) {
      throw new InvalidCharacterError("'atob' failed: The string to be decoded is not correctly encoded.");
    }
    for (
      // initialize result and counters
      var bc = 0, bs, buffer, idx = 0, output = '';
      // get next character
      buffer = input.charAt(idx++);
      // character found in table? initialize bit storage and add its ascii value;
      ~buffer && (bs = bc % 4 ? bs * 64 + buffer : buffer,
        // and if not first of each 4 characters,
        // convert the first 8 bits to one ascii character
        bc++ % 4) ? output += String.fromCharCode(255 & bs >> (-2 * bc & 6)) : 0
    ) {
      // try to find character in table (0-63, not found => -1)
      buffer = chars.indexOf(buffer);
    }
    return output;
  });

}());

(function (window) {
    'use strict';

    window.addEventListener = window.addEventListener || function (type, listener) {
        window.attachEvent && window.attachEvent('on' + type, listener);
    };

    function inArray(item, keys) {
        for (var key in keys) {
            if (keys.hasOwnProperty(key) && keys[key] === item) {
                return true;
            }
        }
        return false;
    }

    var document = window.document,
        location = window.location;

    var config = window.customizerConfig || {};

    var defaultConfig = {
        contentUrl: window.customizerUrl,
        landingPageUrl: location.href.replace(location.hash, ''),
        shoppingCartUrl: null,
        shoppingCartMethod: null,
        optionalParameters: null,
        fullscreenTriggerWidth: null,
        mobileTriggerWidth: null,
        enableIframeHeightFix: false
    };

    // Add config defaults
    for (var key in defaultConfig) {
        if (defaultConfig.hasOwnProperty(key) && !config.hasOwnProperty(key)) {
            config[key] = defaultConfig[key];
        }
    }

    function Frame() {
        this._initialize();
        this._bind();
    }

    Frame.prototype._initialize = function () {
        var instance = this,
            container = document.getElementById('customizer'),
            element = document.createElement('iframe');

        element.width = '100%';
        element.height = '100%';
        element.frameBorder = '0';
        element.src = this._url();
        element.onload = function () {
            instance.content = element.contentWindow || element;
            instance._resize();
            instance._fixHeight();
        };

        container && container.appendChild(element);

        this.element = element;
        this.container = container;
    };

    Frame.prototype._bind = function () {
        var timeout;

        function handleWindowSizeChange() {
            if (timeout) {
                window.clearTimeout(timeout);
            }
            timeout = window.setTimeout(function () {
                instance._resize();
                instance._fixHeight();
            }, 50);
        }

        var instance = this;

        window.addEventListener('resize', handleWindowSizeChange, false);
        window.addEventListener('orientationchange', handleWindowSizeChange, false);
        window.addEventListener('hashchange', function () {
            instance._hashChange();
        }, false);
    };

    Frame.prototype._resize = function () {
        if (!config.fullscreenTriggerWidth || config.enableIframeHeightFix ||
            !!this.disableWarning
            ) {
            return;
        }
        this.showWarning = !!this.showWarning;
        var showWarning = Math.min(document.documentElement.clientWidth, screen.width) < config.fullscreenTriggerWidth;
        if (showWarning === this.showWarning) {
            return;
        }
        this.showWarning = showWarning;
        this.content && this.content.postMessage('showWarning=' + showWarning, '*');
    };

    Frame.prototype._url = function () {
        var result = {};
        var reservedKeys = ['contentUrl', 'fullscreenTriggerWidth', 'enableIframeHeightFix'];

        for (var key in config) {
            if (!inArray(key, reservedKeys) && config.hasOwnProperty(key) && config[key] !== null) {
                result[key] = config[key];
            }
        }

        var hash = this._removeHashbang(location.hash) || '#/';
        hash = hash.replace(/\?.+$/, '');

        return config.contentUrl + hash + '?config=' + window.btoa(encodeURIComponent(JSON.stringify(result)));
    };

    Frame.prototype._removeHashbang = function (hash) {
        var hash = hash;
        if (hash.slice(0, 2) === '#!') {
            hash = '#' + hash.slice(2);
        }
        return hash;
    };

    Frame.prototype._hashChange = function () {
        if (!!this.ignoreHashChange) {
            this.ignoreHashChange = false;
            return;
        }
        this.element.src = this._url();
    };

    Frame.prototype._fixHeight = function () {
        if (!config.enableIframeHeightFix) {
            return;
        }

        if (!this.initialHeight) {
            this.initialHeight = this.container.offsetHeight;
        }

        this.container.style.height = this.initialHeight + 'px';

        var height = Math.min(document.documentElement.clientHeight, window.innerHeight);
        if (height === parseInt(this.container.style.height, 10)) {
            return;
        }

        this.container.style.height = Math.min(this.initialHeight, height) + 'px';
    };

    /**
     * @param {object} updatedConfig
     */
    Frame.prototype.updateConfig = function (updatedConfig) {
        for (var key in updatedConfig) {
            if (updatedConfig.hasOwnProperty(key) && (!config.hasOwnProperty(key) || null === config[key])) {
                config[key] = updatedConfig[key];
            }
        }
    };

    /**
     * @param {string} [hash]
     * @returns {string|undefined}
     */
    Frame.prototype.hash = function (hash) {
        if (!hash) {
            return this._removeHashbang(location.hash);
        }
        this.ignoreHashChange = true;
        location.hash = '!' + hash;
    };

    Frame.instance = function () {
        if (!Frame._instance) {
            Frame._instance = new Frame();
        }
        return Frame._instance;
    };

    Frame.initialize = function () {
        return Frame.instance();
    };

    Frame.initialize();

    window.addEventListener('message', function (evt) {
        if (!evt.data || typeof evt.data !== 'string') {
            console.error('Embed received unsupported message:', evt.data);
            return;
        }
        var data = evt.data.split(/=/);

        switch (data[0]) {
        case 'updateConfig':
            var parsedData;
            try{
                parsedData = JSON.parse(data[1])
                Frame.instance().updateConfig(
                    JSON.parse(data[1])
                );
            } catch(e){
                console.error('Could not parse data', data[1]);
            }
            break;
        case 'disableWarning':
            Frame.instance().disableWarning = true;
            break;
        case 'hashChange':
            Frame.instance().hash(data[1]);
            break;
        case 'redirectTo':
            location.href = decodeURIComponent(data[1]);
            break;
        default:
            console.error('Embed did not recognize message:', evt.data);
            break;
        }
    }, false);

}(window));
