'use strict';

angular.module('sdk').directive('rosterRow', function(_) {
    return {
        restrict: 'A',
        replace: false,
        transclude: true,
        templateUrl: function(element, attributes) {
            return (attributes.type === 'editable') ? 'rosterRowEdit.html' : 'rosterRowPrint.html';
        },
        link: function(scope, element, attributes, controller) {
            if (attributes.type === 'printable') {
                return;
            }

            var doNotDelete = attributes.doNotDelete === "true";
            scope.isFocused = false;

            scope.onFocus = function() {
                scope.isFocused = true;
            };

            scope.onBlur = function() {
                scope.isFocused = false;
            };

            scope.validateNumber = function(key, value) {
                if(_.isString(value)) {
                    value = value.replace(/\D+/g, ''); // allow only digits
                    value = value.replace(/\s+/g, ''); // remove spaces
                }
                if(value === 0) {
                    value = '';
                }

                scope.player[key] = value;

                scope.onChange();
            };

            scope.onChange = function() {
                if(doNotDelete) return;
                if (!scope.player.number && !scope.player.name) {
                    scope.$emit('removeRow', scope.$index);
                }
            };
        }
    }
});
