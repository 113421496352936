'use strict';

angular.module('sdk').directive('tile', function (assetConfig) {
    return {
        restrict: 'E',
        replace: true,
        templateUrl: function(element, attributes) {
            return 'tiles/' + (attributes.tileTemplate || 'standard') + 'Tile.html';
        },
        link: function(scope, element, attributes) {
            var block = element;

            if (!element.hasClass('tiles__item')) {
                block = element.children().eq(0);
            }

            scope.item = scope.optionData.item(scope.itemCode);

            if(!scope.item){
                console.error("Could not find item " + scope.itemCode + " in property " + scope.optionData.$path + '. Legal values are ' + scope.optionData.legalValues())
                return
            }

            // set background color if "hex" value is available for "item"
            if (scope.item.props.hex) {
                scope.$watch('item.props.hex', updateBackgroundColor);
            }

            // set background image if item has "icon" key with the path to a image file
            if (scope.item.icon && !scope.item.embed) {
                scope.$watch('item.icon', updateBackgroundImage);
            }

            function updateBackgroundColor(hex) {
                if (typeof(scope.item.props.hex) === 'string') {
                    block.css('background-color', hex);
                }
            }

            function updateBackgroundImage(path) {
                block.css('background-image', 'url(' + path + '?' + assetConfig.minorVersion + ')');
            }
        }
    }
});
