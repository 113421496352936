'use strict';

angular.module('sdk').factory('getColorFromProperty', function (_, getHexFromCode) {

    return function getColorFromProperty(name, properties) {
        var value = properties;

        _.each(name.split('.'), function (key) {
            value = value[key];
        });

        return getHexFromCode(value);
    }

});
