'use strict';

/*
    A data-centric utility to store user selection and help in presenting all the different options.
    Remembers all historic values and uses that knowledge to pick the best match when the selected item is not availale anymore.
*/

angular.module('sdk').factory('MultiSelectProperty', function (_, $filter) {

    var MultiSelectProperty = function(legalValueList, itemMap) {
        this.$path = null;
        this._valueList = [];
        this._valueListHistory = [];

        this.$legalValueList = legalValueList;
        this.$itemMap = itemMap;
    };

    angular.extend(MultiSelectProperty.prototype, {
        'valueList': function() {
            if (arguments.length > 0) {
                return this._setValueList(arguments[0]);
            } else {
                return this._valueList;
            }
        },

        'legalValues': function() {
            if (arguments.length > 0) {
                return this._setLegalValues(arguments[0]);
            } else {
                return this.$legalValueList;
            }
        },

        'hasValue': function(value) {
            return _.contains(this._valueList, value);
        },

        'hasValues': function() {
            return (!!this._valueList.length);
        },

        'item': function(value) {
            return this.$itemMap[value];
        },

        'toggleValue': function(value) {
            this.setValueEnabled(value, !_.contains(this._valueList, value));
            return this;
        },

        'setValueEnabled': function(value, enabled) {
            if (!enabled) {
                this._setValueList(_.without(this._valueList, value));
            } else {
                if (!this._validateValue(value)) {
                    return;
                }

                this._setValueList(_.union(this._valueList, [value]));
            }

            return this;
        },

        '_setLegalValues': function(legalValueList) {
            if (this.$legalValueList == legalValueList) {
                return this;
            }

            var toBeRemoved = _.difference(this.$legalValueList, legalValueList);
                toBeRemoved = _.intersection(toBeRemoved, this._valueList);

            this.$legalValueList = legalValueList;

            if (toBeRemoved.length > 0) {
                var valueList = _.difference(this._valueList, toBeRemoved);

                if (valueList.length == 0) {
                    valueList = this._getLegalValueListFromHistory();
                }

                this._setValueList(valueList);
            }

            return this;
        },

        '_setValueList': function(valueList) {
            if (this._valueList.length > 0) {
                this._valueListHistory.unshift(this._valueList);
            }

            this._valueList = valueList;
        },

        '_validateValue': function(value) {
            if (!_.contains(this.$legalValueList, value)) {
                console.error(value, 'not present in', this.$legalValueList);

                return false;
            }

            return true;
        },

        '_getLegalValueListFromHistory': function() {
            return _.find(this._valueListHistory, function (pastValueList) {
                return _.difference(pastValueList, this.$legalValueList).length == 0
                // return _.every(pastValueList, function (pastValue) {
                //     return _.contains(this.$legalValueList, pastValue);
                // })
            }, this) || [];
        }
    });

    return MultiSelectProperty;
});
