'use strict'

angular.module('sdk').value('safeApply', function safeApply(scope, fn) {
    // Use scope$apply only if currently not in digest of apply phases
    // Typically flash executes callbacks synchronously, but there are situations when an unrelated flash event is received during the digest phase.
    // http://stackoverflow.com/questions/12729122/prevent-error-digest-already-in-progress-when-calling-scope-apply
    if(scope.$$phase || scope.$root.$$phase) {
        fn()
    } else {
        scope.$apply(fn)
    }
})
