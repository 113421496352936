'use strict';

angular.module('sdk').factory('extractGetParameters', function (_) {
    return function (url) {
        var urlParts = url.split('?'),
            searchString = urlParts[1],
            searchParams,
            params = [];

        if (searchString) {
            searchParams = searchString.split('&');

            _.each(searchParams, function (param) {
                var parts = param.split('='),
                    key = parts[0],
                    value = parts[1];

                params.push({
                    'name': key,
                    'value': value
                });
            });
        }

        return params;
    }
});
