'use strict';

/*
    A data-centric utility to store user selection and help in presenting all the different options.
    Remembers all historic values and uses that knowledge to pick the best match when the selected item is not available anymore.
*/

angular.module('sdk').factory('LimitedProperty', function (_) {
    var LimitedProperty = function (legalValueList, itemMap, allowNone) {
        if(angular.isUndefined(legalValueList) || legalValueList === null) {
            console.error('legalValueList', legalValueList, 'is not valid - an empty array will be used');
        }
        if(angular.isUndefined(itemMap) || itemMap === null) {
            console.error('itemMap', itemMap, 'is not valid - an empty object will be used');
        }

        this._value = null;
        this._valueHistory = [];
        this.isUserDefined = false;

        this.$path = null;
        this.$allowNone = !!allowNone;
        this.$legalValueList = legalValueList || [];
        this.$itemMap = itemMap || {};
        this.$illegalValue = null;
        this.$userSelectedValue = null;
    };

    angular.extend(LimitedProperty.prototype, {
        value: function () {
            if (arguments.length > 0) {
                return this._setValue(arguments[0], arguments[1]);
            } else {
                return this._value;
            }
        },

        legalValues: function () {
            if (arguments.length > 0) {
                return this._setLegalValues(arguments[0]);
            } else {
                return this.$legalValueList;
            }
        },

        lastRealValue: function (fallbackValue) {
            var realValue = _.find(this._valueHistory, function (value) {
                return (value !== 'none' && !!value);
            });
            return realValue || fallbackValue;
        },

        item: function () {
            return this.$itemMap[arguments[0] || this._value];
        },

        itemMap: function (itemMap) {
            this.$itemMap = itemMap;
        },

        text: function () {
            return this.item().name;
        },

        setItemMap: function (itemMap) {
            this.$itemMap = itemMap;
        },

        // TODO: specify if 'none' should be treated this way
        hasValue: function () {
            return (this._value !== 'none' && !!this._value);
        },

        _setValue: function (value, isUserDefined) {
            // console.log(this.$path, '- _setValue from', this._value, 'to', value);
            if(isUserDefined) {
                this.$userSelectedValue = value;
            }
            this.isUserDefined = isUserDefined || this.isUserDefined;

            if (value === this._value) {
                return this;
            }

            if (!this.$allowNone && !_.contains(this.$legalValueList, value)) {
                // console.warn(this.$path, '- Failed to set value "' + value + '", not present in', this.$legalValueList, 'allowNone:', this.$allowNone);
                this.$illegalValue = value;
                return this;
            }

            if (this._value !== null) {
                this._valueHistory.unshift(this._value);
            }

            this._value = value;
            this.$illegalValue = null;

            return this;
        },

        _setLegalValues: function (legalValueList) {
            // console.log(this.$path, '- _setLegalValues from', this.$legalValueList, 'to', legalValueList);
            if (this.$legalValueList === legalValueList) {
                return this;
            }
            this.$legalValueList = legalValueList;

            if (!this.$allowNone && !_.contains(this.$legalValueList, this._value)) {
                var illegalValue = this.$illegalValue;
                var newValue = this._getLegalValueFromHistory() || this._getFirstLegalValue();
                // console.log(this.$path, '- _setLegalValues changing value from', this._value, 'to', newValue);
                this._setValue(newValue);
                this.$illegalValue = illegalValue; // this action should not influence illegalValue
                // console.log(this.$path, '- ', this.$illegalValue, this.$userSelectedValue);
            }

            if (this.$illegalValue && _.contains(this.$legalValueList, this.$illegalValue)) {
                // console.log(this.$path, '- Re-applying illegalValue from' + this._value + 'to' + this.$illegalValue);
                this._setValue(this.$illegalValue);
            }

            if (this.$userSelectedValue !== this._value && _.contains(this.$legalValueList, this.$userSelectedValue)) {
                // console.log(this.$path, '- Re-applying userSelectedValue from' + this._value + 'to' + this.$userSelectedValue);
                this._setValue(this.$userSelectedValue);
            }

            return this;
        },

        _getLegalValueFromHistory: function () {
            return _.find(this._valueHistory, function (value) {
                return _.contains(this.$legalValueList, value);
            }, this);
        },

        _getFirstLegalValue: function () {
            return !_.isEmpty(this.$legalValueList) ? this.$legalValueList[0] : null;
        }
    });

    return LimitedProperty;
});
