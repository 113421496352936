'use strict';

angular.module('sdk').factory('menuService', function (_, menuDataMap) {

    var visitedMenus = {};

    function checkMenuVisits(menuName) {
        var menuData = menuDataMap[menuName],
            visitedCount = 0,
            visitedType = 'partial';

        if (!menuData || !_.has(menuData, 'submenus')) {
            return 'full';
        }

        _.each(menuData.submenus, function (submenuName) {
            var submenuVisitedType = visitedMenus[submenuName];

            if (submenuVisitedType === 'full') {
                visitedCount++;
            }
        });

        if (visitedCount === menuData.submenus.length) {
            visitedType = 'full';
        }

        return visitedType;
    }

    function checkAsVisited(menuName) {
        var menuData = menuDataMap[menuName];

        if (!menuName) {
            return;
        }

        visitedMenus[menuName] = checkMenuVisits(menuName);

        if (!menuData) {
            return;
        }

        if (menuData.parent) {
            checkAsVisited(menuData.parent);
        }
    }

    function getVisitedMenus() {
        return visitedMenus;
    }



    return {
        'checkAsVisited': checkAsVisited,
        'getVisitedMenus': getVisitedMenus
    }
});
