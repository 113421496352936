'use strict';


angular.module('sdk').factory('warningService', function ($rootScope, $location, iframeService, popupService) {

    var disableWarning = false;

    function toggleWarning(isVisible) {
        isVisible = '' + isVisible === 'true';

        $rootScope.$apply(function () {
            $rootScope.url = $location.absUrl();

            if (isVisible) {
                disableWarning = true;
                popupService.openPopup('warning');
            } else if ($rootScope.popupName === 'warning') {
                disableWarning = false;
                popupService.closePopup();
            }
        });

        $rootScope.$on('closePopup', function () {
            disableWarning && iframeService.sendMessage('disableWarning', disableWarning);
        });
    }

    iframeService.handleMessage('showWarning', toggleWarning);

    return toggleWarning;
});
