'use strict';

angular.module('sdk').factory('traverseDeep', function (_) {

    function traverseDeep(object, handler) {
        _.each(object, function(value, key){
            handler(object, key, value);
            if(_.isObject(value)) {
                traverseDeep(value, handler);
            }
        });
    }
    return traverseDeep;
});
