'use strict';

angular.module('sdk').directive('navigationControls', function (vendorConfig) {
    return {
        restrict: 'E',
        replace: true,
        templateUrl: 'navigationControls.html',
        controller: function ($scope) {
            $scope.changeMenu = function (menuName) {
                $scope.$emit('changeMenu', menuName);
            };
        },
        link: function(scope, element, attributes) {
            scope.orderSectionTitle = vendorConfig.ui.orderSectionTitle;
            scope.shareSectionTitle = vendorConfig.ui.shareSectionTitle;
        }
    }
});
