'use strict';

angular.module('sdk').directive('roster', function (_, rosterSizes, rosterDataOutput) {

    var ROW_DEFAULT_DATA = {
            number: '',
            name: '',
            size: 'M',
            qty: 1
        };

    return {
        restrict: 'E',
        replace: true,
        templateUrl: 'roster.html',
        scope: {
            rosterDataOutput: '=?'
        },
        controller: function($scope, $element, $attrs) {

            var rosterType = $attrs.rosterType;
            var sortBy     = $attrs.sortBy;

            $scope.rosterType = rosterType;
            $scope.sizeList = rosterSizes;
            $scope.emptyItem = angular.copy(ROW_DEFAULT_DATA);
            $scope.rosterDataOutput = $scope.rosterDataOutput || rosterDataOutput;
            $scope.rosterDataOutputExtended = [$scope.emptyItem];
            $scope.removePlayer = removeRow;

            $scope.$watchCollection('rosterDataOutput', function (rosterDataOutput) {
                $scope.rosterDataOutputExtended = [];

                _.each(rosterDataOutput, function (player) {
                    $scope.rosterDataOutputExtended.push(player);
                });

                if (rosterType === 'editable') {
                    $scope.rosterDataOutputExtended.push($scope.emptyItem);
                }
            });

            $scope.$watch('emptyItem', function (emptyItem) {
                if (!emptyItem.number && !emptyItem.name) {
                    return;
                }

                $scope.rosterDataOutput.push($scope.emptyItem);

                $scope.emptyItem = angular.copy(ROW_DEFAULT_DATA);

                $scope.rosterDataOutputExtended.push($scope.emptyItem);
            }, true);

            $scope.$on('removeRow', function (e, index) {
                removeRow(index);
            });

            function removeRow(index) {
                // will not remove last roster row (emptyItem)
                if (index === ($scope.rosterDataOutputExtended.length - 1)) {
                    return;
                }

                // has to remove player from both rosters
                $scope.rosterDataOutputExtended.splice(index, 1);
                $scope.rosterDataOutput.splice(index, 1);
            }

            function sortBySize() {

                var sizeIndexMap = _.map(rosterSizes.codeMap, function(sizeName, sizeCode) {
                    return sizeCode
                });

                var entryOrderIndex = function(entry) {
                    return sizeIndexMap.indexOf(entry.size);
                };

                var rosterDataOutputSorted = _.sortBy($scope.rosterDataOutput, entryOrderIndex);
                _.each(rosterDataOutputSorted, function(entry, index){
                    $scope.rosterDataOutput[index] = entry;
                })
            }

            if (sortBy === 'size') {
                $scope.$on('$destroy', sortBySize);
            }

        }
    }
});
