'use strict';

angular.module('sdk').directive('textInput', function ($timeout) {
    return {
        restrict: 'E',
        scope: {
            optionData: '='
        },
        replace: true,
        templateUrl: 'inputText.html',
        link: function (scope, element, attrs) {
            var regexp = attrs.regexp ? new RegExp('[^' + attrs.regexp + ']', 'gi') : null;
            var debounce = +attrs.debounce || 0;
            var timeout;

            function filter() {
                if (regexp) {
                    scope.value = scope.value.replace(regexp, '');
                }
            }

            function update(_debounce) {
                function _updateModelValue() {
                    scope.optionData.value(scope.value);
                }

                filter();

                if (timeout) {
                    $timeout.cancel(timeout);
                }
                if (_debounce) {
                    timeout = $timeout(_updateModelValue, _debounce);
                } else {
                    _updateModelValue();
                }
            }

            function change() {
                update(debounce);
            }

            function blur() {
                update(0);
            }

            scope.change = change;
            scope.blur = blur;

            scope.$watch('optionData.value()', function (value) {
                scope.value = value;
            });
        }
    };
});
