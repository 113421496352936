'use strict';

angular.module('sdk').factory('dataModelHelper', function () {

    /**
     * @constructor
     */
    function DataModelHelper() {
    }

    var p = DataModelHelper.prototype;

    /**
     * @param {Object} data
     */
    p.begin = function (data) {
        this.data = JSON.parse(JSON.stringify(data));
    };

    /**
     * @returns {Object}
     */
    p.finish = function () {
        var data = this.data;
        this.data = null;
        return data;
    };

    /**
     * @param {String} name
     * @param {*} [value]
     * @returns {*}
     * @private
     */
    p.property = function (name, value) {
        var path = this.data,
            keys = name.split('.'), key, index, end;

        for (index in keys) {
            key = keys[index];
            end = index == keys.length - 1;

            if (!path[key]) {
                path[key] = end ? '' : {};
            }

            if (end) {
                if (value === undefined) {
                    value = path[key];
                } else if (value !== null) {
                    path[key] = value ? value : '';
                } else {
                    value = path[key];
                    delete path[key];
                }
            } else {
                path = path[key];
            }
        }

        return value;
    };

    /**
     * @method getProperty
     * @param {String} name
     * @type {Function}
     * @returns {*}
     **/
    p.getProperty = p.property;

    /**
     * @method setProperty
     * @param {String} name
     * @param {*} value
     * @type {Function}
     * @returns {*}
     **/
    p.setProperty = p.property;

    /**
     * @param a
     * @param b
     */
    p.renameProperty = function (a, b) {
        this.property(b, this.property(a, null));
    };

    /**
     * @param {String} name
     */
    p.deleteProperty = function (name) {
        delete this.data[name];
    };

    /**
     * @param {String} string
     * @param {String} prefix
     * @returns {String}
     */
    p.removePrefix = function (string, prefix) {
        return string.substr(prefix.length, 1).toLowerCase() + string.substr(prefix.length + 1);
    };

    /**
     * @param a
     * @param [b]
     */
    p.createObjectByPrefix = function (a, b) {
        b = b || a;
        var property;
        for (property in this.data) {
            if (property.indexOf(a) === 0) {
                if (!this.data[b]) {
                    this.data[b] = {};
                }
                this.data[b][this.removePrefix(property, a)] = this.data[property];
                delete this.data[property];
            }
        }
    };

    /**
     * @param {Object} [data]
     */
    p.convertNullToNone = function (data) {
        data = data || this.data;

        var self = this;

        Object.keys(data).forEach(function (key) {
            if (data[key] === null) {
                data[key] = 'none';
            } else if ((typeof data[key] === 'object')
                && !(data[key] instanceof Array)
                ) {
                self.convertNullToNone(data[key]);
            }
        });
    };

    /**
     * @param {String} name
     */
    p.convertPropertyValueToCamelCase = function (name) {
        var value = this.property(name);

        value = ('' + value).replace(/\s/g, '');
        value = value.substr(0, 1).toLowerCase() + value.substr(1);

        this.property(name, value);
    };

    return new DataModelHelper();
});
