'use strict';

angular.module('sdk').directive('buttonMarker', function (_, designData, designDataOutput, menuService, colorList) {

    function resolveObjectString(obj, s) {
        var parts = s.split('.');

        if (!obj[parts[0]]) {
            return null;
        }

        parts.forEach(function (part) {
            if (obj[part]) {
                obj = obj[part];
            } else {
                obj = null;
            }
        });

        return obj;
    }



    return {
        restrict: 'E',
        replace: true,
        scope: {
            markerSource: '@'
        },
        templateUrl: function(element, attributes) {
            return 'markers/' + attributes.markerType + '.html';
        },
        link: function(scope, element, attributes) {
            var markerType = attributes.markerType;

            function updateMarker() {
                switch (markerType) {
                    case 'color': {
                        var colorData = resolveObjectString(designData, scope.markerSource),
                            colorCode;

                        if (!colorData) {
                            return;
                        }

                        colorCode = colorData.value();

                        scope.colorCode = colorCode;
                        scope.colorName = colorList.itemMap[colorCode].name;
                    }
                    break;

                    case 'check': {
                        var visitedList = menuService.getVisitedMenus(),
                            visitedType = 'none';

                        if (_.has(visitedList, scope.markerSource)) {
                            visitedType = visitedList[scope.markerSource];
                        }

                        scope.visitedType = visitedType;
                    }
                    break;
                }
            }

            switch (markerType) {
                case 'color':
                case 'check': {
                    updateMarker();

                    scope.$watch('designDataOutput', updateMarker, true);
                    scope.$watch(menuService.getVisitedMenus, updateMarker, true);
                }
                break;

                case 'delete': {
                    element.bind('click', function (e) {
                        e.stopPropagation();
                    });
                }
                break;
            }
        },
        controller: function($scope) {
            $scope.unsetValue = function(value) {
                var markerData = resolveObjectString(designData, $scope.markerSource);

                markerData.value(value || null);

                $scope.$emit('unsetValue');
            };

            $scope.designDataOutput = designDataOutput;
        }
    }
});
