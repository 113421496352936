'use strict';

angular.module('jarden').directive('printTitle', function (designData, getTemplate) {
    return {
        restrict: 'E',
        replace: true,
        scope: {},
        templateUrl: 'printTitle.html',
        link: function(scope, element, attributes) {
            scope.designData = designData;
            scope.getTemplate = getTemplate;
        }
    }
});
