'use strict';

angular.module('sdk').directive('menuButton', function() {
    return {
        restrict: 'E',
        replace: true,
        transclude: true,
        scope: {
            isActive: '=',
            isHidden: '=',
            buttonText: '@'
        },
        templateUrl: 'menuButton.html',
        link: function(scope, element, attributes) {
            scope.changeMenu = function() {
                scope.$emit('changeMenu', scope.isActive ? '' : attributes.menuId);
            };
        }
    }
});
