'use strict';

angular.module('sdk').factory('base64Service', function ($window) {
    return {
        encode: function (str) {
            return /*escape(*/$window.btoa(encodeURIComponent(str))/*)*/;
        },
        decode: function (str) {
            return decodeURIComponent($window.atob(unescape(str)));
        }
    }
});
