'use strict';

angular.module('jarden').factory('getOrderData', function (projectIdHandler, designDataUtils) {
   return function() {
        return {
            'ConfigurationID': projectIdHandler.getProjectID(),
            'SKU': designDataUtils.getSkuData().sku,
            'price': designDataUtils.getSkuData().price || undefined,
        };
    }
});
