'use strict';

angular.module('jarden').directive('vendorHomepage', function (vendorConfig) {
    return {
        restrict: 'E',
        replace: true,
        scope: {},
        templateUrl: 'vendor/homepage-link.html',
        link: function(scope, element, attributes) {
            scope.vendorConfig = vendorConfig;
        }
    }
});
