'use strict';

angular.module('sdk').factory('getObjectFromString', function (colorList) {

    return function getObjectFromString(s, obj) {
        var parts = s.split('.'),
            part,
            i;

        for (i = 0; i < parts.length; i++) {
            part = parts[i];

            if (obj[part]) {
                obj = obj[part];
            } else {
                obj = null;

                // exit, no need to continue the loop
                break;
            }
        }

        return obj;
    };

});
