'use strict';

angular.module('sdk').run(function (_, $window, $location, loadSaveOrderConfig) {
    if ($window.xdomain) {
        $window.xdomain.slaves(
            _.chain(loadSaveOrderConfig)
            .map(getDomain)
            .uniq()
            .transform(fillDomains, {})
            .value()
        );
    }

    function getDomain(url) {
        var protocol = $location.protocol();
        return protocol.concat(':').concat(url.split('/').slice(0, 3).join('/'));
    }

    function fillDomains(slaves, domain) {
        slaves[domain] = '/proxy.html';
    }
});
