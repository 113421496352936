'use strict';

angular.module('sdk').factory('updateDesignData', function (_, designData) {

    function getPropertyPath(basePath, propertyCode) {
        var path = _.union(basePath, []);
            path.push(propertyCode);
        return path;
    }

    function copyPropertyValue(property, code) {
        var basePath = this.basePath,
            inputValue = this.input[code],
            isUserDefined = this.isUserDefined;

        if (_.isUndefined(inputValue)) {
            // console.error('Missing property in input designData -', getPropertyPath(basePath, code).join('.'));
            return;
        }

        if (property.value) {
            property.value(inputValue, isUserDefined);
            return;
        }

        if (property.valueList) {
            property.valueList(inputValue);
            return;
        }

        if (_.isString(inputValue) || _.isNumber(inputValue)) {
            var entry = designData;
            _.each(basePath, function(key) {
                entry = entry[key];
            });

            entry[code] = inputValue;
        }

        if (_.isObject(property)) {
            _.each(property, copyPropertyValue, {
                'input': inputValue,
                'basePath': getPropertyPath(basePath, code),
                'isUserDefined': isUserDefined
            });
        }
    }

    return function (designDataInput, isUserDefined) {
        _.each(designData, copyPropertyValue, {
            'input': designDataInput,
            'basePath': [],
            'isUserDefined': isUserDefined
        });
    }
});
