'use strict';
/**
/ Prevent mouse wheel scrolling on certain elements
**/
angular.module('sdk').directive('olvPreventScroll', function ($timeout) {
    return {
        restrict: 'A',
        link: function (scope, element, attributes) {
            element.on('mousewheel', function(event) {
                event.preventDefault();
                event.stopImmediatePropagation();
                return false;
            });
        }
    };
});
