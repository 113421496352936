'use strict';

angular.module('sdk').factory('designDataOutput', function (_, $rootScope, designData) {
    function updatePropertyPath(property, key) {
        var output = this;
        var path = this.concat(key);

        if (property.$path === null) {
            property.$path = path.join('.');
        } else if (_.isObject(property)) {
            _.each(property, updatePropertyPath, path);
        }
    }
    _.each(designData, updatePropertyPath, []);


    var scope = $rootScope.$new(true);
        scope.designData = designData;
        scope.$watch('designData', updateOutput, true);

    var designDataOutput = {};

    function updateOutput() {
        _.each(designData, updateOutputProperty, designDataOutput);
    }

    function updateOutputProperty(property, code) {
        var output = this;

        if (property.value) {
            output[code] = property.value();
        } else if (property.valueList) {
            output[code] = property.valueList();
        } else if(_.isString(property) || _.isNumber(property)) {
            output[code] = property;
        }
        else if (_.isObject(property)) {
            if(!output[code])
                output[code] = {};

            _.each(property, updateOutputProperty, output[code]);
        }
    }

    return designDataOutput;
});
