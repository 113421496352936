'use strict';

angular.module('sdk').factory('objectToDashed', function (stringToDashed, _) {

    function objectToDashed(sourceObject, resultObject) {
        var resultObject = resultObject || {};

        _.each(sourceObject, function (sourceValue, key) {
            if(_.isNumber(key)) {
                key = key.toString();
            }

            var newKey = stringToDashed(key);
            if(_.isObject(sourceValue)) {
                resultObject[newKey] = objectToDashed(sourceValue);
            } else {
                resultObject[newKey] = sourceValue;
            };
        });

        return resultObject;
    }

    return objectToDashed;
});
