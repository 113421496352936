'use strict';

angular.module('sdk').service('dataModelUpgrader', function () {
    this._upgraders = {};
    this.registerUpgrader = function(versionFrom, upgrader) {
        this._upgraders[versionFrom] = upgrader;
    };
    this.upgrade = function(projectData) {
        var versionFrom = projectData.dataModelVersion;
        var upgrader = this._upgraders[versionFrom];
        if(!upgrader) {
            return null;
        }

        try {
            var data = upgrader(projectData);
        } catch(err) {
            console.warn(err);
        }
        return data;
    };
});
