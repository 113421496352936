'use strict';

angular.module('sdk').controller('MenuController', function ($scope, designDataOutput, designDataUtils) {

    $scope.designDataOutput = designDataOutput;
    $scope.designDataUtils = designDataUtils;

    // we need this $scope function to write inline links, eg. «Attention, <a href="" ng-click="changeMenu('frontLogo')">Front Logo</a> is overlapping with <a href="" ng-click="changeMenu('frontNumber')">Front Number</a>!»
    $scope.changeMenu = function (menuName) {
        $scope.$emit('changeMenu', menuName);
    };

    $scope.$on('$includeContentLoaded', function (e) {
        e.targetScope.menuData = $scope.menuData;
    });

});
