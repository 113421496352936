'use strict';

angular.module('sdk').directive('warning', function () {
    return {
        restrict: 'E',
        replace: true,
        templateUrl: 'warning.html',
        link: function(scope, element, attributes) {}
    }
});
