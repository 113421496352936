'use strict';

angular.module('sdk').directive('mouseOnlyCheck', function () {
    return {
        restrict: 'A',
        link: function (scope, element, attrs) {
            var isTouch = false;

            if (('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch) {
                angular.element(document).bind('touchstart touchmove', function (evt) {
                    element.removeClass('mouse-only');
                    isTouch = true;
                });

                angular.element(document).bind('mousemove', function (evt) {
                    if (!isTouch) {
                        element.addClass('mouse-only');
                    }
                    isTouch = false;
                });
            } else {
                element.addClass('mouse-only');
            }
        }
    };
});
