'use strict';

angular.module('sdk').directive('ngRenderFinished', function ($timeout) {
    return {
        restrict: 'A',
        link: function (scope, element, attributes) {
            $timeout(function () {
                scope.$emit('ngRenderFinished');
            });
        }
    };
});
