'use strict';

angular.module('sdk').factory('configService', function (_, $window, $document, $location, base64Service, iframeService, vendorConfig, consoleService) {

    var externalConfigString = getConfigFromSearch() || getConfigFromHash();
    var externalConfigJSONString;
    var externalConfig = {};
    if (externalConfigString) {
        try {
            var externalConfigJSONString = base64Service.decode(externalConfigString);
        } catch (err) {
            consoleService.reportError('ConfigError', 'Unable to decode config string.\n\tConfig: ' + externalConfigString + '\n\tRefferer: ' + (document.referrer || 'none'));
        }
    }
    if(externalConfigJSONString) {
        try {
            externalConfig = JSON.parse(externalConfigJSONString);
        } catch (err) {
            consoleService.reportError('ConfigError', 'Unable to parse config JSON.\n\tConfig: ' + externalConfigJSONString + '\n\tRefferer: ' + (document.referrer || 'none'));
        }
    }

    function getConfigFromSearch() {
        var params = {},
            search = $window.location.search;

        if (!search) {
            return;
        }

        if (search.substring(0, 1) === '?') {
            search = search.substring(1);
        }

        angular.forEach(search.split('&'), function (param) {
            var parts = param.split('=');
            params[parts[0]] = parts[1];
        });

        if (!_.isString(params.config)) {
            return;
        }

        return params.config;
    }

    function getConfigFromHash() {
        var search = $location.search();

        if (!_.isString(search.config)) {
            return;
        }

        return search.config;
    }

    _.merge(vendorConfig, $window.customizerConfig, externalConfig); // deep extend from Lo-Dash

    vendorConfig.hashPrefix = vendorConfig.hashPrefix || '';

    iframeService.sendMessage('updateConfig', JSON.stringify({
        'fullscreenTriggerWidth': vendorConfig.fullscreenTriggerWidth,
        'enableIframeHeightFix': vendorConfig.enableIframeHeightFix
    }));

    return {
        externalConfigString: externalConfigString
    }
});
