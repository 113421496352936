'use strict';

angular.module('sdk').factory('constructShareUrl', function (_, shareConfig, vendorConfig) {
    return function (url) {
        return {
            'facebook': _.template(shareConfig.facebook)({
                'url': url
            }),

            'twitter': _.template(shareConfig.twitter)({
                'text': vendorConfig.twitter.text,
                'hashtags': vendorConfig.twitter.hashtags,
                'related': vendorConfig.twitter.related,
                'via': vendorConfig.twitter.via ? '&via=' + vendorConfig.twitter.via : '',
                'url': url
            }),

            'google': _.template(shareConfig.google)({
                'url': url
            }),

            'mail': _.template(shareConfig.mail)({
                'text': url
            })
        }
    }
});
