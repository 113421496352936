'use strict';

angular.module('sdk').factory('printService', function ($window, $location, $timeout) {

    function print() {
        $location.search('print', null);

        // $timeout hack is used to avoid "$digest already in progress" error thrown by "CanvasAdapter.prototype._resize"
        $timeout(function () {
            $window.print();
        });
    }

    return {
        'print': print
    }
});
