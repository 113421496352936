'use strict';

angular.module('sdk').value('stringToDashed', function(s) {
    s = s.replace(/([A-Z])(?=[a-z])/g, '-$1');

    s = s.replace(/\s+/, '-');
    s = s.replace(/-+/, '-');

    return s.toLowerCase();
});
