'use strict';

angular.module('sdk').factory('previewService', function (_, $location, $q, $rootScope, previewAdapters, designData, consoleService, safeApply) {

    var forcedAdapterName = $location.search().preview,
        activeAdapter;

    function getAdapter() {
        if (activeAdapter) {
            return activeAdapter;
        }

        activeAdapter = _.find(previewAdapters.adapters, function (adapter, name) {
            return forcedAdapterName ? name === forcedAdapterName : adapter.supported();
        });

        return activeAdapter;
    }

    function getSnapshots() {
        if(designData.isReversed && designData.isReversed.isDisabled && !designData.isReversed.isDisabled()){
            return getReversibleSnapshots()
        }
        return activeAdapter.getSnapshots();
    }

    function getReversibleSnapshots() {
        var reversibleSnapshots = {}

        function storeReversibleSnapshots(snapshots){
            reversibleSnapshots[designData.isReversed.value()] = snapshots
        }

        function awaitReversibleSwitch(){
            var deferred = $q.defer()
            var onRenderedCallback = activeAdapter.callbacks.onRendered
            activeAdapter.callbacks.onRendered = function(){
                activeAdapter.callbacks.onRendered = onRenderedCallback
                deferred.resolve()
            }
            safeApply($rootScope, function(){
                designData.isReversed.value(designData.isReversed.value() === 'on' ? 'off' : 'on')
            })
            return deferred.promise
        }

        function packageSnapshots(){
            _.each(reversibleSnapshots.on, function(snapshot){
                snapshot.viewName += 'Inside'
            })
            return reversibleSnapshots.off.concat(reversibleSnapshots.on)
        }

        function getSnapshotsAndSwitchInside(){
            return activeAdapter.getSnapshots()
                .then(storeReversibleSnapshots)
                .then(awaitReversibleSwitch)
        }

        return getSnapshotsAndSwitchInside()
            .then(getSnapshotsAndSwitchInside)
            .then(function(){
                activeAdapter.callbacks.onRendered()
            })
            .then(packageSnapshots)
            .catch(function(err){
                consoleService.reportError('PreviewService', err)
            })
    }

    return {
        'getAdapter': getAdapter,
        'getSnapshots': getSnapshots
    }
});
