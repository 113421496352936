'use strict';

angular.module('sdk').factory('projectSaver', function ($http, RequestCanceler, loadSaveOrderConfig, consoleService) {
    var requestCanceler = new RequestCanceler();

    function save(projectData) {
        requestCanceler.cancel();

        var url = loadSaveOrderConfig.saveUrl;

        return $http({
            'method': 'POST',
            'url': url,
            'data': projectData,
            'timeout': requestCanceler.promise
        })
        .error(function (data, status, headers, config) {
            consoleService.reportError('HttpPostError', 'Unable to save project data (status: ' + status + ', message: ' + (data.error || data) + ')', url, projectData);
        });
    }

    function cancel() {
        requestCanceler.cancel();
    }

    return {
        'save': save,
        'cancel': cancel
    }
});
