'use strict';

angular.module('sdk').factory('dataModelVersionInterceptor', function (dataModelVersion, dataModelUpgrader) {

    function makeResponseFailing(res, errorCode, errorMessage){
        res.code = errorCode;
        res.data = {
            message: errorMessage,
            code: errorCode
        };
    }

    return {
        response: function(res) {
            if (!res.config.dataModelVersionInterceptor) {
                return res;
            }

            res.data.dataModelVersion = (function (result) {
                result = result || '0.0.0';
                result += '';

                var dots = (result.match(/\./g) || []).length;
                if (dots >= 2) {
                    return result;
                }

                for (var i = 3 - dots; i > 1; i--) {
                    result += '.0';
                }

                return result;
            })(res.data.dataModelVersion);

            if (res.data.dataModelVersion !== dataModelVersion) {
                var updatedData = dataModelUpgrader.upgrade(res.data);

                if (!updatedData) {
                    makeResponseFailing(
                        res,
                        415,
                        'Failed to update dataModelVersion from ' + res.data.dataModelVersion + " to " + dataModelVersion + " for url: " + res.config.url
                    );
                } else {
                    res.data = updatedData;
                }
            }

            return res;
        }
    }
});
