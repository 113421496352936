'use strict';

angular.module('sdk').factory('keyboardService', function (_, $window) {

    var listeners = {};

    /**
     * @param {string} name
     * @param {number} keyCode
     * @param {function} callback
     */
    function on(name, keyCode, callback) {
        if (!_.has(listeners, keyCode)) {
            listeners[keyCode] = {};
        }

        listeners[keyCode][name] = callback;
    }

    /**
     * @param {string} name
     * @param {number} keyCode
     */
    function off(name, keyCode) {
        delete listeners[keyCode][name];
    }

    function keyEvents(e) {
        if (_.has(listeners, e.which)) {
            _.each(listeners[e.which], function (listener) {
                listener();
            });
        }
    }



    angular.element($window).bind('keyup', keyEvents);



    return {
        'on': on,
        'off': off
    }
});
