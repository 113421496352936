'use strict';

angular.module('sdk').factory('excludeLayers', function (_) {

    return function excludeLayers(layerOrViewList, excludedLayers, activeView) {
        var views;
        if(activeView) {
            views = {};
            views[activeView] = layerOrViewList;
        } else {
            views = layerOrViewList
        }

        if (excludedLayers && excludedLayers.length) {
            _.each(excludedLayers, function (layer) {
                var layerData = layer.split('/'),
                    layerView = layerData[0],
                    layerName = layerData[1];

                var excludedView = _.findWhere(views[layerView], {'name': layerName});

                views[layerView] = _.without(views[layerView], excludedView);
                views[layerView] = _.compact(views[layerView]);
            });
        };

        return (activeView) ? views[activeView] : views;
    }

});
