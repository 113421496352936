'use strict';

angular.module('sdk').factory('popupService', function (_, $rootScope, $timeout, projectSaver, projectOrderer, keyboardService) {

    var popupHistory = [],
        popupLocked = false;

    function openPopup(popupName) {
        $timeout(function () {
            popupHistory.push(popupName);
            $rootScope.popupName = popupName;
            keyboardService.on('popup', 27, exitPopup);
        });
    }

    function closePopup(e) {
        e && e.stopPropagation();

        if (popupLocked) {
            return;
        }

        // cancel project saving/ordering if save is in progress
        projectSaver.cancel();
        projectOrderer.cancel();

        popupHistory.pop();

        $rootScope.popupName = _.last(popupHistory);

        if (popupHistory.length === 0) {
            keyboardService.off('popup', 27, exitPopup);
        }
    }

    function exitPopup() {
        $rootScope.$apply(function () {
            closePopup();
        });
    }

    function lockPopup(toggle) {
        popupLocked = toggle;
    }

   function changeTitle(title) {
        // To be hijacked by popup directoive
    };

    return {
        'openPopup': openPopup,
        'closePopup': closePopup,
        'lockPopup': lockPopup,
        'changeTitle': changeTitle
    }
});
