'use strict';

angular.module('sdk').factory('ImageAdapter', function ($q, $timeout, getTemplate, $analytics) {
    function log(action, label){
        $analytics.eventTrack(action, {  category: 'ImagePreview', label: label, nonInteraction: true });
    }

    var ImageAdapter = function ImageAdapter(options) {
        this.name = 'image';
        this.options = angular.extend({}, options);
        this.isInitialized = false;
    };

    ImageAdapter.prototype.supported = function () {
        return true;
    };

    ImageAdapter.prototype.initialize = function (scope, callbacks) {
        log('Initialize');
        this.scope = scope;
        this.callbacks = callbacks;
        this.container = document.getElementById('imagePreviewPlaceholder');
        this.image = new Image();

        this.isInitialized = true;

        this.callbacks.onInitialized && this.callbacks.onInitialized();

        this.container.insertBefore(this.image, this.container.firstChild);

        this._bind();
        this._show();
    };

    ImageAdapter.prototype._bind = function() {
        var image = this.image;
            image.onload = angular.bind(this, this.onRendered);
            image.onerror = image.onload;
    };

    ImageAdapter.prototype._show = function() {
        this.onRender();

        this.image.src = 'assets/snapshots/' + getTemplate().code + '.jpg';
    };

    ImageAdapter.prototype.update = function (designData) {
        this._show();
    };

    ImageAdapter.prototype.onRender = function() {
        $timeout(
            angular.bind(this, function () {
                this.callbacks.onRender && this.callbacks.onRender();
            })
        );
    };

    ImageAdapter.prototype.onRendered = function() {
        $timeout(
            angular.bind(this, function () {
                this.callbacks.onRendered && this.callbacks.onRendered();
            })
        );
    };

    ImageAdapter.prototype.getSnapshot = function (snapshotConfig) {
        var deferred = $q.defer();

        var canvas = document.createElement('canvas');
            canvas.width = snapshotConfig.width;
            canvas.height = snapshotConfig.height;

        var ctx = canvas.getContext('2d');
            ctx.drawImage(this.image, 0, 0);

        var dataUrl = canvas.toDataURL('image/jpg');

        deferred.resolve({
            'viewName': snapshotConfig.viewName,
            'imgUrl': dataUrl
        });

        return deferred.promise;
    };

    ImageAdapter.prototype.getSnapshots = function () {
        var snapshots = [];

        _.each(this.options.snapshotList, function (snapshotConfig) {
            snapshots.push(this.getSnapshot(snapshotConfig));
        }, this);

        return $q.all(snapshots);
    };


    ImageAdapter.prototype.changeActiveView = function(viewName) {};

    return ImageAdapter;
});
