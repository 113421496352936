'use strict'

angular
    .module('sdk')
    .directive('vendorOrderForm', function(
        _,
        $timeout,
        vendorConfig,
        getModifiedDesignDataForOrder,
        getSummary,
        designData,
        designDataOutput,
        extractGetParameters,
        objectToDashed,
        flattenDashed,
        previewUploader,
        projectIdHandler,
        getOrderData
    ) {
        return {
            restrict: 'E',
            scope: {},
            replace: true,
            require: '^order',
            templateUrl: 'vendorOrderForm.html',
            link: function(scope, element, attrs, orderCtrl) {
                function getMethodParts() {
                    var shoppingCartMethod = vendorConfig.shoppingCartMethod
                    if (
                        shoppingCartMethod === 'GET' ||
                        shoppingCartMethod === 'POST'
                    ) {
                        shoppingCartMethod = 'FLAT-' + shoppingCartMethod
                    }
                    var shoppingCartMethodParts = shoppingCartMethod.split('-')
                    return {
                        dataMethod: shoppingCartMethodParts[0],
                        httpMethod: shoppingCartMethodParts[1],
                    }
                }

                scope.execute = function() {
                    var exportData
                    var formFields = []
                    var methodParts = getMethodParts()
                    var dataMethod = methodParts.dataMethod
                    var httpMethod = methodParts.httpMethod

                    function pushField(value, name) {
                        formFields.push({
                            name: name,
                            value: value,
                        })
                    }

                    // extracting GET parameters from vendor config's "shoppingCartUrl" parameter
                    if (httpMethod === 'GET') {
                        formFields = extractGetParameters(
                            vendorConfig.shoppingCartUrl
                        )
                    }

                    switch (dataMethod) {
                        case 'FLAT':
                        case 'JSON':
                            exportData = getModifiedDesignDataForOrder(
                                designDataOutput
                            )
                            exportData = objectToDashed(exportData) // all keys in data object should be in dashed notation
                            break
                        case 'LABELED':
                            // TODO find a way to apply to LimitedProperty
                            // designData = getModifiedDesignDataForOrder(
                            //     designData
                            // )
                            exportData = {
                                configuration: getSummary(designData),
                            }
                            break
                        default:
                            throw new Error(
                                "Unsupported dataMethod '" + dataMethod + "'"
                            )
                    }

                    if (dataMethod === 'FLAT') {
                        // GET and POST data object should be also flattened
                        exportData = flattenDashed(exportData)
                    }

                    // extending export data with additional parameters
                    previewUploader &&
                        _.extend(exportData, previewUploader.getData())
                    vendorConfig &&
                        _.extend(exportData, vendorConfig.optionalParameters)
                    getOrderData && _.extend(exportData, getOrderData())

                    switch (dataMethod) {
                        case 'FLAT':
                            _.each(exportData, pushField)
                            break
                        case 'JSON':
                            pushField(
                                httpMethod === 'POST'
                                    ? exportData
                                    : JSON.stringify(exportData),
                                'json'
                            )
                            break
                        case 'LABELED':
                            pushField(
                                httpMethod === 'POST'
                                    ? exportData
                                    : JSON.stringify(exportData),
                                'labeled'
                            )
                            break
                        default:
                            throw new Error(
                                "Unsupported dataMethod '" + dataMethod + "'"
                            )
                    }

                    scope.formFields = formFields

                    element.attr('method', httpMethod)
                    element.attr('action', vendorConfig.shoppingCartUrl)
                    $timeout(function() {
                        // must delay to allow scope formFields to populate
                        element[0].submit()
                    })
                }

                orderCtrl.registerVendorOrderFormScope(scope)
            },
        }
    })
