'use strict';

angular.module('sdk').directive('menuTitle', function() {
    return {
        restrict: 'E',
        replace: true,
        transclude: true,
        scope: {
            buttonText: '@',
            menuParent: '@'
        },
        templateUrl: 'menuTitle.html',
        link: function(scope, element, attributes) {
            scope.changeMenu = function() {
                scope.$emit('changeMenu', scope.menuParent);
            };
        }
    }
});
