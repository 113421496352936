'use strict';

angular.module('sdk').factory('extendDeep', function (_) {


    // ### _.objMap
    // _.map for objects, keeps key/value associations
    function objMap(input, mapper, context) {
        return _.reduce(input, function(obj, v, k) {
            obj[k] = mapper.call(context, v, k, input);
            return obj;
        }, {}, context);
    }

    // https://gist.github.com/mikermcneil/3862932
    function extend(original, newObj) {
        // TODO: make this work for more than one newObj
        // var newObjects = _.toArray(arguments).shift();

        return _.extend(original, objMap(newObj, function(newVal, key) {
            var oldVal = original[key];

            // If the new value is a non-object or array,
            // or the old value is a non-object or array, use it
            if(_.isArray(newVal) || !_.isObject(newVal) || _.isArray(oldVal) || !_.isObject(oldVal)) {
                return newVal || oldVal;
            }
            // Otherwise, we have to descend recursively
            else {
                return extend(oldVal, newVal);
            }
        }));
    }

    return extend;
});
