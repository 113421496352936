'use strict';

angular.module('sdk').directive('share', function ($timeout, $window, projectIdHandler, urlService, constructShareUrl, configService, vendorConfig) {
    return {
        restrict: 'E',
        replace: true,
        templateUrl: 'share.html',
        link: function(scope, element, attributes) {
            var shareUrl;

            function getShareUrl() {
                var url = urlService.getShareUrl(true);
                return constructShareUrl(encodeURIComponent(url));
            }

            function saveProject() {
                scope.saveStatus = 'processing';
                scope.saveMessage = 'Saving...';

                projectIdHandler.saveProject().then(saveSuccess, saveError);
            }

            function saveSuccess() {
                var saveInput = angular.element(document.getElementById('saveInput'));

                saveInput.bind('focus', function() {
                    this.select();
                });

                shareUrl = getShareUrl();

                scope.saveMessage = urlService.getShareUrl(true);
                scope.saveStatus = 'success';
                scope.mailtoLink = shareUrl.mail;

                $timeout(function() {
                    saveInput.triggerHandler('focus');
                });
            }

            function saveError() {
                scope.saveStatus = 'error';
                scope.saveMessage = 'Error!';
            }

            scope.share = function(socialServiceName) {
                $window.open(shareUrl[socialServiceName], '', 'width=626, height=436');
            };

            saveProject();
        }
    }
});
