angular.module('jarden').directive('reversibleSwitcher', function (_, designData) {
    return {
        restrict: 'E',
        replace: true,
        scope: true,
        transclude: true,
        templateUrl: 'reversible-switcher.html',
        controller: function ($scope, $element, $attrs) {
            var prop = _.get(designData, $attrs.prop)
            var value = $attrs.value
            $scope.iconClass = 'icon_reversed-' + value
            $scope.canSetValue = function() {
                return !prop.isDisabled() && prop.value() !== value && _.contains(prop.legalValues(), value)
            }
            $scope.apply = function() {
                prop.value(value)
            }
        },
    }
})
