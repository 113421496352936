'use strict';

angular.module('sdk').directive('designSummary', function (designData, designDataUtils, urlService, previewService, getTemplate, menuDataMap) {
    return {
        restrict: 'E',
        scope: {
            status: '@',
            togglable: '@',
            printable: '@'
        },
        replace: true,
        templateUrl: 'designSummary.html',
        controller: function($scope) {
            $scope.designData = designData;
            $scope.getTemplate = getTemplate;
            $scope.designDataUtils = designDataUtils;
            $scope.urlService = urlService;
            $scope.previewService = previewService;
            $scope.menuDataMap = menuDataMap;

            $scope.toggle = function() {
                if ($scope.togglable) {
                    $scope.status = ($scope.status === 'expanded') ? 'collapsed' : 'expanded';
                }
            };
        }
    }
});
