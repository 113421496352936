'use strict';

angular.module('sdk').factory('iframeService', function ($rootScope, $location) {

    var handlers = {};

    window.addEventListener = window.addEventListener || function (type, listener) {
        window.attachEvent && window.attachEvent('on' + type, listener);
    };

    window.addEventListener('message', function (evt) {
        if (!evt.data || typeof evt.data !== 'string') {
            console.error("App received unsupported message:", evt.data);
            return;
        }

        var data = evt.data.split(/=/),
            action = data[0],
            value = data[1];

        var handler = handlers[action];

        if (handler) {
            for (var index in handler) {
                if (handler.hasOwnProperty(index)) {
                    handler[index](value);
                }
            }
        } else {
           console.error("App did not recognize message:", evt.data);
        }
    }, false);

    $rootScope.$on('$routeChangeSuccess', function () {
        sendMessage('hashChange', $location.path());
    });

    function handleMessage(action, handler) {
        if (!handlers[action]) {
            handlers[action] = [];
        }
        handlers[action].push(handler);
    }

    function handleMessageOnce(action, handler) {
        if (!handlers[action]) {
            handlers[action] = {};
        }

        var id = +new Date();
        handlers[action][id] = clearHandlerAfterExec(action, id, handler);
    }

    function clearHandlerAfterExec(action, id, handler) {
        return function(value) {
            handler(value);
            delete handlers[action][id];
        }
    }

    function sendMessage(action, value) {
        if (!isInsideIframe()) {
            return;
        }

        window.parent.postMessage(action + '=' + value, '*');
    }

    function isInsideIframe() {
        return (window.parent && window.parent.window !== window);
    }

    return {
        handleMessage: handleMessage,
        handleMessageOnce: handleMessageOnce,
        sendMessage: sendMessage,
        isInsideIframe: isInsideIframe,
        iframeParams: $location.search()
    }
});
