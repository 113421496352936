'use strict';

angular.module('sdk').directive('mobileCheck', function ($rootScope, $window, $timeout, vendorConfig) {
    return {
        restrict: 'A',
        link: function (scope, element, attrs) {
            var timeout;

            function addRemoveClass() {
                var isMobile = $window.innerWidth < vendorConfig.mobileTriggerWidth;

                if (isMobile === scope.isMobile) {
                    return;
                }

                scope.isMobile = isMobile;

                if (isMobile) {
                    element.addClass("is_mobile");
                } else {
                    element.removeClass("is_mobile");
                }

                scope.$emit("switch_mobile");
            }

            angular.element($window).bind('resize', function() {
                $timeout.cancel(timeout);
                timeout = $timeout(addRemoveClass, 50);
            });
            angular.element($window).bind('orientationchange', addRemoveClass);

            addRemoveClass();
        }
    }
});
