'use strict';

/**
 Helps assuring that only one requst is active at a time.
 cancel the previous request to obtain the promise for populating the timeout property on http request
    requestCanceler.cancel()
    ..
    $http({timeout: requestCanceler.promise})
**/

angular.module('sdk').factory('RequestCanceler', function ($q) {
    var RequestCanceler = function() {};

    angular.extend(RequestCanceler.prototype, {
        _requestCanceler: null,
        promise: null,
        cancel: function() {
            if (this._requestCanceler) {
                this._requestCanceler.resolve();
            }

            this._requestCanceler = $q.defer();
            this.promise = this._requestCanceler.promise;

            return this;
        }
    });

    return RequestCanceler;
});
