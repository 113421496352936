var BacisCanvasPreviewProvider = function(angular, _, $q, getCanvasLayers, shadowBlendingMethod, templateIdHandler) {
    'use strict';

    var LayerImage = LayerImageProvider($q, angular.bind);
    var LayerImageCors = LayerImageCorsProvider(LayerImage);
    var LayerImageCorsBlob = LayerImageCorsBlobProvider($q, angular.bind, LayerImage);
    var LayerImageCorsBase64 = LayerImageCorsBase64Provider($q, angular.bind, LayerImage);
    var LayerImageDoNotLoad = LayerImageDoNotLoadProvider($q);
    var Layer = LayerProvider();
    var ImageLoader = ImageLoaderProvider(LayerImage, LayerImageCors, LayerImageCorsBlob, LayerImageCorsBase64, LayerImageDoNotLoad, $q, angular.forEach, angular.bind, _.pluck, _.contains);
    var LayerStack = LayerStackProvider(Layer, getCanvasLayers, ImageLoader, shadowBlendingMethod, $q, angular.forEach, angular.bind, angular.copy, angular.element, _.pluck, _.contains);
    var RenderImage = RenderImageProvider(LayerStack, angular.equals, angular.copy, angular.bind, angular.element, $q);
    var CanvasPreview = CanvasPreviewProvider(RenderImage, angular.forEach, angular.equals, angular.bind, angular.copy, $q, _.findWhere, templateIdHandler);

    return CanvasPreview;
};
;var CanvasPreviewProvider = function(RenderImage, $forEach, $equals, $bind, $copy, $q, _findWhere, templateIdHandler) {
    'use strict';

    /**
     * @param {HTMLElement} element
     * @param {Object} options
     */
    var CanvasPreview = function(element, options, hdpi) {
//        var Signal = signals.Signal;
        this.zoomState = true;
        this._views = {};
        this.options = options;
        this.element = element;
        this.hdpi = hdpi || false;
    };

    CanvasPreview.prototype.initialize = function(activeView) {
        this.sizes = this._getSizes(this.options.sizes);

        $forEach(this.options.collections, function (data) {
            this._views[data.code] = new RenderImage(data.code);
            this.element.append(this._views[data.code].container);
        }, this);

        this._activeView = activeView;
        this._views[activeView].show();
    };

    CanvasPreview.prototype.changeActiveView = function(activeView) {
        var deferred = $q.defer();

        this._activeView = activeView;

        if (activeView === '*') {
            this._showAllViews().then(function() {
                deferred.resolve(true);
            });

            return deferred.promise;
        }

        $forEach(this._views, function (view) {
            if (view.name === this._activeView) {
                view.validate().then(
                    $bind(this, function() {
                        if (view.name === this._activeView) {
                            view.show();
                            this.resize();
                        }

                        deferred.resolve(false);
                    }),
                    function() {
                        deferred.reject();
                    }
                );
            } else {
                view.hide();
            }
        }, this);

        return deferred.promise;
    };

    CanvasPreview.prototype._showAllViews = function() {
        var deferred = $q.defer();
        var viewCount = Object.keys(this._views).length;
        var renderedViews = [];

        $forEach(this._views, function (view) {
            view.validate().then(
                $bind(this, function() {
                    view.show();
                    this.resize();

                    renderedViews.push(view.name);

                    if (viewCount === renderedViews.length) {
                        deferred.resolve();
                    }
                })
            );
        }, this);

        return deferred.promise;
    };

    CanvasPreview.prototype.update = function(designData) {
        var deferred = $q.defer();

        this.sizes = this._getSizes(this.options.sizes);

        $forEach(this._views, function (view) {
            view.update(designData);

            if (view.isVisible()) {
                this.resize();

                view.validate().then(
                    function() {
                        deferred.resolve(false);
                    },
                    function() {
                        deferred.reject();
                    }
                );
            }
        }, this);

        return deferred.promise;
    };

    CanvasPreview.prototype.getViewDataUrl = function(viewName, sizeId) {
        var deferred = $q.defer();
        var view = this._views[viewName];
        var size = _findWhere(this.sizes[viewName], { id: sizeId }) || view.size;
        var originalSize = view.size;

        view.setSize(size);
        view.validate()
            .catch(deferred.reject)
            .then(function() {
                var dataUrl = view.getDataUrl();

                view.setSize(originalSize);

                if (view.isVisible()) {
                    view.validate();
                }

                deferred.resolve({
                    'viewName': viewName,
                    'imgUrl': dataUrl
                });
            });

        return deferred.promise;
    };

    CanvasPreview.prototype.zoomIn = function() {
    };

    CanvasPreview.prototype.zoomOut = function() {
    };

    CanvasPreview.prototype.views = function() {
        return this._views;
    };

    CanvasPreview.prototype.resize = function() {
        var deferred = $q.defer();

        var containerSize = this._getContainerSize();

        $forEach(this._views, function (view) {
            var preferredSize = this._preferredSize(this.sizes[view.name], containerSize);

            this._updateCSS(view, preferredSize);
            view.setSize(preferredSize);

            if (view.isVisible()) {
                view.validate().then(
                    function() {
                        deferred.resolve(false);
                    },
                    function() {
                        deferred.reject();
                    }
                );
            }
        }, this);

        return deferred.promise;
    };

    CanvasPreview.prototype._updateCSS = function (view, size) {
        var containerSize = this._getContainerSize();

        var scaleWidth = containerSize.width / size.width;
        var scaleHeight = containerSize.height / size.height;
        var scale = Math.min(1, scaleWidth, scaleHeight);

        var width = ~~(size.width * scale);
        var height = ~~(size.height * scale);

        var positionY = (containerSize.height - height) / 2;
        var positionY = Math.max(0, positionY);

        var transform = 'translate(0px, ' + positionY + 'px)';

        view.applyCSS({
            'width': width + 'px',
            'height': height + 'px',
            'transform': transform,
            '-ms-transform': transform,
            '-webkit-transform': transform
        });
    };

    CanvasPreview.prototype._getContainerSize = function() {
        return {
            'width': this.element[0].clientWidth,
            'height': this.element[0].clientHeight
        };
    };

    CanvasPreview.prototype._preferredSize = function(sizes, containerSize) {
        var reversedSizes = $copy(sizes).reverse();

        var devicePixelRatio = (!this.hdpi) ? 1 : window.devicePixelRatio || 1;

        var preferredSize = reversedSizes[0];

        for (var i = 0; i < reversedSizes.length; i++) {
            if (reversedSizes[i].width >= containerSize.width * devicePixelRatio || reversedSizes[i].height >= containerSize.height * devicePixelRatio) {
                preferredSize = reversedSizes[i];
            }
        }

        return preferredSize;
    };

    CanvasPreview.prototype._getSizes = function(sizes) {
        var template = this._getCurrentTemplate();
        var result = {};

        $forEach(sizes, function (options, id) {
            var sizeOptions = angular.copy(options);

            $forEach(sizeOptions, function (option) {
                if (option.ratio && template in option.ratio) {
                    option.width = Math.round(option.height * option.ratio[template]);
                } else if (!options.height && options.width) {
                    option.height = Math.round(option.width * option.ratio[template]);
                }
                delete option.ratio;
            });

            result[id] = sizeOptions;
        });
        return result;
    };

    CanvasPreview.prototype._getCurrentTemplate = function() {
        return templateIdHandler.getTemplateID();
    };

    return CanvasPreview;
};
;window.ImageLoaderProvider = function(LayerImage, LayerImageCors, LayerImageCorsBlob, LayerImageCorsBase64, LayerImageDoNotLoad, $q, $forEach, $bind, _pluck, _contains) {
    'use strict';

    var ImageLoader = function() {
        this._images = [];
        this._list = {};
        this._cancelLoad = false;
        this.corsClass = null;

        var corsClasses = [
            LayerImageCors,
            LayerImageCorsBlob,
            LayerImageCorsBase64,
            LayerImageDoNotLoad
        ];

        $forEach(corsClasses, $bind(this, function (corsClass) {
            if (!this.corsClass && corsClass.supported) {
                this.corsClass = corsClass;
            }
        }));
    };

    var p = ImageLoader.prototype;

    p.setImageList = function(list) {
        var oldList = this._list;
        this._list = list;
        this._unloadImages(oldList);
    };

    p.loadImages = function() {
        var deferred = $q.defer();
        var total = this._list.length;
        var loaded = 0;
        this._loadInProgress = true;

        $forEach(this._list, function (data) {
            this._getOrCreateImage(data).loadImage()
            .then($bind(this, function() {
                loaded++;
            }), function() {
                total--;
            })
            .finally($bind(this, function() {
                if (loaded >= total) {
                    this._loadInProgress = false;
                    if(this._cancelLoad) {
                        this._cancelLoad = false;
                        deferred.reject();
                    } else {
                        deferred.resolve();
                    }
                }
            }));
        }, this);

        return deferred.promise;
    };

    p.getImages = function(src) {
        return this._images[src];
    };

    p._unloadImages = function(oldList) {
        this._cancelLoad = false;

        if (this._loadInProgress) {
            this._cancelLoad = true;
            var layerSrcArr = _pluck(this._list, 'src');
            $forEach(oldList, function (layer) {
                if(!_contains(layerSrcArr, layer.src)) {
                    if(!this._images[layer.src].loaded) {
                        this._images[layer.src].stopLoading();
                        delete this._images[layer.src];
                    }
                }
            }, this);
        }
    };

    p._getOrCreateImage = function(data) {
        var image = this._images[data.src];
        if (!image) {
            if (data.crossOrigin) {
                if (!this.corsClass) {
                    throw new Error('CORS strategy not set.');
                }

                image = this._images[data.src] = new this.corsClass(data.src);
            } else {
                image = this._images[data.src] = new LayerImage(data.src);
            }
        }
        return image;
    };

    return ImageLoader;
};
;//Handles canvas layer.
//Wraper around image data, for ease of use of CanvasStack.

var LayerProvider = function() {
    'use strict';

    var Layer = function(canvas) {
        // this.options = _.extend(this.options, options);
    };

    Layer.prototype.getImageData = function() {
        if (!this.imageData) {
            this.imageData = this._getContext().getImageData(0, 0, this._getWidth(), this._getHeight());
        }
        return this.imageData;
    };

    Layer.prototype.setImage = function(image) {
        this.imageData = null;

        this._setWidth(image.width);
        this._setHeight(image.height);

        this._getContext().drawImage(image, 0, 0);
    };

    Layer.prototype.setColor = function(color) {
        if (!color) {
            return;
        }

        var context = this._getContext();

        context.globalCompositeOperation = 'source-atop';
        context.fillStyle = color;
        context.fillRect(0, 0, this._getWidth(), this._getHeight());
    };


    Layer.prototype._getWidth = function() {
        return this.getCanvas().width;
    };

    Layer.prototype._getHeight = function() {
        return this.getCanvas().height;
    };

    Layer.prototype._setWidth = function(width) {
        this.getCanvas().width = width;
    };

    Layer.prototype._setHeight = function(height) {
        this.getCanvas().height = height;
    };

    Layer.prototype.getCanvas = function() {
        if (!this.canvas) {
            this.canvas = document.createElement('canvas');
        }
        return this.canvas;
    };

    Layer.prototype._getContext = function() {
        if (!this.context) {
            this.context = this.getCanvas().getContext('2d');
        }
        return this.context;
    };

    return Layer;
};
;window.LayerStackProvider = function(Layer, getCanvasLayers, ImageLoader, shadowBlendingMethod, $q, $forEach, $bind, $copy, $element, _pluck, _contains) {
    'use strict';

    var LayerStack = function(viewName, cacheLayers) {
        this._cacheLayers = cacheLayers;

        // this.images = {};
        this.layers = {};

        this.canvas = document.createElement('canvas');
        this._context = this.canvas.getContext('2d');
        this.container = $element(this.canvas);
        this._isValid = false;
        this._loadInProgress = false;

        this._viewName = viewName;

        this.imageLoader = new ImageLoader();
    };


    LayerStack.prototype.loadAndDraw = function(designDataOutput) {
        this._designDataOutput = $copy(designDataOutput);
        this._list = getCanvasLayers(this._designDataOutput, this.size, this._viewName);
        var deferred = $q.defer();

        this.imageLoader.setImageList(this._list);

        var that = this;
        this.imageLoader.loadImages()
            .then(
                function() {
                    that._drawLayers().then(
                        function() {
                            that._isValid = true;
                            deferred.resolve();
                        }, function() {
                            that._isValid = false;
                        });
                }, function() {
                    that._isValid = false;
                    deferred.reject();
                });

        return deferred.promise;
    };


    LayerStack.prototype.setSize = function(size) {
        this.size = size;
        this.canvas.width = this.size.width;
        this.canvas.height = this.size.height;
        this._isValid = false;
    };


    LayerStack.prototype.hide = function() {
        this._isValid = false;
    };


    LayerStack.prototype.isValid = function() {
        return this._isValid;
    };


    LayerStack.prototype._drawLayers = function() {
        var deferred = $q.defer();

        this._clear();

        $forEach(this._list, function (data) {
            var image = this.imageLoader.getImages(data.src);

            if (!image) {
                throw(new Error('Image "' + data.src + '" should exist!'));
            }

            if(!image.loaded) {
                return;
            }

            var layer = this._getLayer(data.src, image.image);
            this._drawLayer(data, layer);
        }, this);
        deferred.resolve();
        return deferred.promise;
    };


    LayerStack.prototype._getLayer = function(name, image) {
        if (!this._cacheLayers) {
            if (!this.layer) {
                this.layer = new Layer();
            }
            this.layer.setImage(image);
            return this.layer;
        }
        var layer = this.layers[name];
        if (!layer) {
            layer = this.layers[name] = new Layer();
            layer.setImage(image);
        }
        return layer;
    };


    LayerStack.prototype._drawLayer = function(data, layer) {
        layer.setColor(data.color);

        if (data.options && data.options.blend) {
            this._setImageData(shadowBlendingMethod(this._getImageData(), layer.getImageData()));
        } else {
            this._context.drawImage(layer.getCanvas(), 0, 0);
        }

    };


    LayerStack.prototype._getImageData = function() {
        return this._context.getImageData(0, 0, this.size.width, this.size.height);
    };

    LayerStack.prototype._setImageData = function(imageData) {
        this._context.putImageData(imageData, 0, 0);
    };
    LayerStack.prototype._clear = function() {
        this._context.clearRect(0, 0, this.size.width, this.size.height);
    };

    return LayerStack;
};
;window.RenderImageProvider = function(LayerStack, $equals, $copy, $bind, $element, $q) {
    'use strict';

    // Create and maintains canvas DOM element. Update, Resize, Hide/Show.
    // -Switch 'a' and 'b' canvases-
    // Copy data from canvas backbuffer instead of switching.

    var RenderImage = function(viewName, cacheLayers) {
        // TODO: Cache should be disabled only for mobile devices.
        this.name = viewName;
        this._cacheLayers = cacheLayers || false;
        this._stack = new LayerStack(viewName, true);

        this.canvas = document.createElement('canvas');
        this._context = this.canvas.getContext('2d');

        this._context.imageSmoothingEnabled = true;
        this._context.webkitImageSmoothingEnabled = true;
        this._context.mozImageSmoothingEnabled = true;

        this.container = $element(this.canvas);

        this._isValid = false;
        this._isVisible = false;
        this._viewName = viewName;
        this.size = {};
    };


    RenderImage.prototype.validate = function() {
        var deferred = $q.defer();

        if (this._isValid) {
            deferred.resolve();
            return deferred.promise;
        }
        this._stack.loadAndDraw(this._designDataOutput)
            .then(
                $bind(this, function() {
                    this._render();
                    this._isValid = true;
                    deferred.resolve();
                }),
                $bind(this, function() {
                    this._isValid = false;
                    deferred.reject();
                })
            );

        return deferred.promise;
    };

    RenderImage.prototype.update = function(designDataOutput) {
        if (!$equals(this._designDataOutput, designDataOutput)) {
            this._designDataOutput = $copy(designDataOutput);
            this._isValid = false;
        }
    };

    RenderImage.prototype.setSize = function(size) {
        if ($equals(this.size, size)) {
            return;
        }

        this.size = size;
        this._stack.setSize(size);
        this._isValid = false;
    };

    RenderImage.prototype.applyCSS = function(css) {
        this.container.css(css);
    };

    RenderImage.prototype.show = function() {
        this._isVisible = true;
        this.container.addClass('visible');
    };

    RenderImage.prototype.hide = function() {
        if (!this._isValid) {
            this._stack.hide();
        }
        this._isVisible = false;
        this.container.removeClass('visible');
    };

    RenderImage.prototype.isVisible = function() {
        return this._isVisible;
    };

    RenderImage.prototype.isValid = function() {
        return this._isValid;
    };

    RenderImage.prototype._render = function() {
        this.canvas.width = this.size.width;
        this.canvas.height = this.size.height;
        this._context.drawImage(this._stack.canvas, 0, 0);
    };

    RenderImage.prototype.getDataUrl = function() {
        return this.canvas.toDataURL();
    };

    return RenderImage;
};
;// Loads image and return image data

var LayerImageProvider = function($q, $bind) {
    'use strict';

    var LayerImage = function(src) {
        this.src = src;
        this.loaded = false;
        this.deferred = null;
        this.image = null;
    };

    var p = LayerImage.prototype;

    p.loadImage = function() {
        if (this.loaded) {
            return this.deferred.promise;
        }

        if (!this.image) {
            this.deferred = $q.defer();
            this.image = this._newImage();

            this.image.onload = $bind(this, this._onLoad);
            this.image.onerror = $bind(this, this._onError);

            this.image.src = this.src;
        }

        return this.deferred.promise;
    };

    p.stopLoading = function() {
        if (this.loaded) {
            throw new Error('Can\'t stop loading - image already loaded');
        }

        this._unloadImage();
    };

    p._newImage = function() {
        return new Image();
    };

    p._unloadImage = function() {
        if (this.image) {
            this.image.onload = null;
            this.image.onerror = null;
            this.image = null;
        }

        if (!this.loaded) {
            this.deferred.reject();
        }

        this.loaded = false;
        this.deferred = null;
    };

    p._onLoad = function() {
        this.loaded = true;
        this.deferred.resolve();
    };

    p._onError = function() {
        this.deferred.reject();
    };

    LayerImage.supported = true;

    return LayerImage;
};

;//Loads image and return image data

var LayerImageCorsProvider = function(LayerImage) {
    'use strict';

    var LayerImageCors = function(src) {
        this.src = src;
        this.loaded = false;
        this.deferred = null;
    };

    var p = LayerImageCors.prototype = new LayerImage();

    p._newImage = function() {
        var image = new Image();
        image.crossOrigin = 'Anonymous';
        return image;
    };

    LayerImageCors.supported = (new Image()).crossOrigin !== undefined;

    return LayerImageCors;
};

;var LayerImageCorsBase64Provider = function($q, $bind, LayerImage, base64) {
    'use strict';

    var LayerImageCorsBase64 = function(src) {
        this.src = src + '&base64=true';
        this.loaded = false;
        this.deferred = null;
        this.image = null;
        this.xhr = null;
    };

    var p = LayerImageCorsBase64.prototype = new LayerImage();

    p.loadImage = function() {
        if (this.loaded) {
            return this.deferred.promise;
        }

        if (!this.image) {
            this.deferred = $q.defer();

            var image = this.image = this._newImage();
            var xhr = this.xhr = new XMLHttpRequest();

            this.image.onload = $bind(this, this._onLoad);
            this.image.onerror = $bind(this, this._onError);

            this.xhr.onload = function() {
                image.src = xhr.responseText
            };
            this.xhr.onerror = this.image.onerror;

            this.xhr.open('GET', this.src, true);
            this.xhr.send();
        }

        return this.deferred.promise;
    };

    p.LayerImage_unloadImage = p._unloadImage;

    p._unloadImage = function() {
        if (this.xhr) {
            this.xhr.onload = null;
            this.xhr.onerror = null;
            this.xhr = null;
        }

        this.LayerImage_unloadImage();
    };

    LayerImageCorsBase64.supported = (function() {
        var result = false;
        // See http://stackoverflow.com/a/15820461
        var isIE9 = new Function('/*@cc_on return /^9/.test(@_jscript_version); @*/')();
        if (isIE9) {
            result = true;
        }
        return result;
    })();

    return LayerImageCorsBase64;
};

;var LayerImageCorsBlobProvider = function($q, $bind, LayerImage) {
    'use strict';

    var LayerImageCorsBlob = function(src) {
        this.src = src;
        this.loaded = false;
        this.deferred = null;
        this.image = null;
        this.xhr = null;
    };

    var p = LayerImageCorsBlob.prototype = new LayerImage();

    p.loadImage = function() {
        if (this.loaded) {
            return this.deferred.promise;
        }

        if (!this.image) {
            this.deferred = $q.defer();

            var success = $bind(this, this._onLoad);
            var fail = $bind(this, this._onError);

            var image = this.image = this._newImage();

            this.xhr = new XMLHttpRequest();

            this.xhr.onload = function() {
                var url = URL.createObjectURL(this.response);

                image.onload = function() {
                    success();
                    URL.revokeObjectURL(url);
                };
                image.onerror = fail;

                image.src = url;
            };
            this.xhr.onerror = fail;

            this.xhr.open('GET', this.src, true);
            this.xhr.responseType = 'blob';
            this.xhr.send();
        }

        return this.deferred.promise;
    };

    p.LayerImage_unloadImage = p._unloadImage;

    p._unloadImage = function() {
        if (this.xhr) {
            this.xhr.onload = null;
            this.xhr.onerror = null;
            this.xhr = null;
        }

        this.LayerImage_unloadImage();
    };

    LayerImageCorsBlob.supported = (function() {
        var result = false;
        // See http://stackoverflow.com/a/15820461
        var isIE10 = new Function('/*@cc_on return /^10/.test(@_jscript_version); @*/')();
        if (isIE10) {
            result = true;
        }
        return result;
    })();

    return LayerImageCorsBlob;
};

;//Loads image and return image data

var LayerImageDoNotLoadProvider = function($q) {
    'use strict';

    var LayerImageDoNotLoad = function(src) {
        this.deferred = null;
    };

    var p = LayerImageDoNotLoad.prototype;

    p.loadImage = function() {
        this.deferred = $q.defer();
        this.deferred.reject();
        return this.deferred.promise;
    };

    p.stopLoading = function() {
        this.deferred = null;
    };

    LayerImageDoNotLoad.supported = true;

    return LayerImageDoNotLoad;
};

